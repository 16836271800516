import { pipe } from 'lodash/fp';
import { createFilter, withCheck } from '../../common/filters';

const filterParsers = {};

// eslint-disable-next-line import/prefer-default-export
export const filter = pipe(
  withCheck,
  createFilter,
)(filterParsers);
