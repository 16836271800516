import { formatDateTime } from '../common/dates';

const stats = {
  earliestConverted: {
    title: 'Earliest Converted',
    formatValue: formatDateTime,
  },
  latestConverted: {
    title: 'Latest Converted',
    formatValue: formatDateTime,
  },
  convertedMemberships: {
    title: 'Converted Membership',
    colors: (colors) => ({
      background: colors.palette.orange[2],
      title: colors.palette.orange[9],
    }),
  },
  convertedUsers: {
    title: 'Converted Users',
    colors: (colors) => ({
      background: colors.palette.cyan[2],
      title: colors.palette.cyan[9],
    }),
  },
  avantLinkedUsers: {
    title: 'Avant Linked Users',
    colors: (colors) => ({
      background: colors.palette.blue[2],
      title: colors.palette.blue[9],
    }),
  },
  confirmedUsers: {
    title: 'Confirmed Users',
  },
  enabledUsers: {
    title: 'Enabled Users',
  },
  adminUsers: {
    title: 'Admin users',
  },
  usersWithoutMembership: {
    title: 'Users Without Membership',
  },
  usersPendingInviteResponse: {
    title: 'Users Pending Invite Response',
  },
  usersPendingEmailVerification: {
    title: 'Users Pending Email Verification',
  },
  usersPendingPhoneVerification: {
    title: 'Users Pending Phone Verification',
  },
  totalUsers: {
    title: 'Total Users',
    colors: (colors) => ({
      background: colors.palette.gold[2],
      title: colors.palette.gold[9],
    }),
  },
};

export default stats;
