import React from 'react';
import { Button, Modal } from 'antd';
import styled from 'styled-components';
import { chainedPath } from '../../common/util';
import Viewer from './views/Viewer';


const StyledModal = styled(Modal)`
  .ant-modal-content {
  }
`;

export default ({
  log, visible, onOk, onCancel,
}) => {
  const form = chainedPath('form')(log);
  const quoteInput = chainedPath('quoteInput')(log);

  return log ? (
    <StyledModal
      destroyOnClose
      footer={[
        <Button type="primary" onClick={onOk}>OK</Button>,
      ]}
      width="700px"
      centered
      title="Fully detailed view"
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Viewer form={form()} quoteInput={quoteInput()} />
    </StyledModal>
  ) : null;
};
