import React from 'react';
import { Select, Tag } from 'antd';

const statusCodeColors = {
  '2xx': 'green',
  '3xx': 'blue',
  '4xx': 'orange',
  401: 'orange',
  '5xx': 'red',
  errors: 'red',
};

const StatusCodeFilter = (mainProps) => (
  <Select
    placeholder="Status Code"
    mode="multiple"
    {...mainProps}
    tagRender={({ value, label, ...props }) => <Tag color={statusCodeColors[value]} {...props}>{value}</Tag>}
  >
    <Select.Option value="2xx">
      <Tag color={statusCodeColors['2xx']}>2xx</Tag>
    </Select.Option>
    <Select.Option value="3xx">
      <Tag color={statusCodeColors['3xx']}>3xx</Tag>
    </Select.Option>
    <Select.Option value="4xx">
      <Tag color={statusCodeColors['4xx']}>4xx</Tag>
    </Select.Option>
    <Select.Option value="401">
      <Tag color={statusCodeColors[401]}>401</Tag>
    </Select.Option>
    <Select.Option value="5xx">
      <Tag color={statusCodeColors['5xx']}>5xx</Tag>
    </Select.Option>
    <Select.Option value="errors">
      <Tag color={statusCodeColors.errors}>Errors</Tag>
    </Select.Option>
  </Select>
);

export default StatusCodeFilter;
