import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';

const Label = styled(Box)`
  font-size: 10px;
  color: ${(props) => props.theme.colors.palette.blue[5]};
`;

const Value = styled(Box)`
  font-size: 13px;
  color: ${(props) => props.theme.colors.dark};
  font-weight: bold;
`;

const FieldView = ({ label, children, ...props }) => (
  <Box mb={3} {...props}>
    <Label>{label}</Label>
    <Value>{children}</Value>
  </Box>
);

export default FieldView;
