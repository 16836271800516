import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Menu, Popover } from 'antd';
import { createGlobalStyle } from 'styled-components';
import Button from '../atoms/Button';
import useCsvExport from '../hooks/useCsvExport';

const PopoverStyles = createGlobalStyle`
  &&& .csv-export-menu .ant-popover-inner-content {
    padding: 0;
    .ant-menu-item.ant-menu-item-only-child {
      margin: 0;
      :hover {
        color: ${(props) => props.theme.colors.palette.blue[5]};
        background: ${(props) => props.theme.colors.palette.blue[0]};
      }
      &.ant-menu-item-selected {
        background: inherit;
        color: ${(props) => props.theme.colors.palette.blue[5]};
        &:hover {
           background: ${(props) => props.theme.colors.palette.blue[0]};
        }
      }
    }
   }
`;

const CsvExport = (props) => {
  const { state, actions } = useCsvExport(props);
  const {dataSource, ...otherProps} = props;
  return (
    <>
      <PopoverStyles />
      <Popover
        open={state.menuVisible}
        onOpenChange={actions.toggleMenu}
        placement="left"
        overlayClassName="csv-export-menu"
        trigger="click"
        content={(
          <Menu
            onClick={({ key }) => {
            actions.exportToCsv({
              excelStrings: key === 'excel',
            });
          }}
            items={[{
              key: 'excel',
              label: 'Optimised for Excel',
            }, {
              key: 'standard',
              label: 'Standard one'
            }]}
          />
                )}
      >
        <Button
          disabled={!props.dataSource?.length}
          icon={<DownloadOutlined />}
          tooltip="Export to CSV"
          {...otherProps}
        />
      </Popover>
    </>
  );
};

export default CsvExport;
