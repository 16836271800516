import { useMutation } from '@apollo/client';
import UnconvertMembership from '../../../queries/unconvertMembership.graphql';
import { removeAllPersonsFromStore } from '../storeUpdaters';

export default () => {
  const [mutation, ...rest] = useMutation(UnconvertMembership);

  const unconvertMembership = (membershipId) => mutation({
    variables: {
      input: {
        id: membershipId,
      },
    },
    update(store, { data: { adminUnconvertMembership } }) {
      // delete the members-manage  from the current
      // list as the membership does not exist anymore in the system (it stays just in HAMBS)
      if (adminUnconvertMembership.success) {
        removeAllPersonsFromStore(store, membershipId);
      }
    },
  });

  return [unconvertMembership, ...rest];
};
