export default {
  title: 'Eligibility',
  fields: {
    profession: {
      value: (value) => value.value,
    },
    australianCitizen: {
      value: (value) => (value ? 'Yes' : 'No'),
    },
  },
};
