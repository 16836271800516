import { path } from 'lodash/fp';
import ConversionLogsQuery from '../../queries/conversionLogsQuery.graphql';
import usePagedQuery from '../../common/hooks/usePagedQuery';
import {NETWORK_STATUS} from "../../common/constants";

const getNext = path('oms.admin.logs.conversion.next');
const getRecords = path('oms.admin.logs.conversion.logs');
export default () => {
  const [getLogs, { data, refetch, networkStatus, loading, ...rest }] = usePagedQuery(ConversionLogsQuery, {
    fetchPolicy: 'network-only',
    nextLot: getNext,
    notifyOnNetworkStatusChange: true,
    queryInput: (filters) => ({
      startTime: filters.date.start,
      endTime: filters.date.end,
      membershipId: filters.membershipId || undefined,
      email: filters.email || undefined,
    }),
    onCompleted: (data, { variables } = {}) => {
      const nextToken = getNext(data);
      if (!getRecords(data)?.length && nextToken) {
        refetch((currentVariables) => {
          return {
            input: {
              ...currentVariables?.input,
              next: nextToken
            }
          };
        });
      }
    },
  });


  return [getLogs, {
    refetch,
    data: getRecords(data),
    loading: [NETWORK_STATUS.INITIAL_LOADING, NETWORK_STATUS.REFETCHING].includes(networkStatus),
    ...rest,
  }];
};
