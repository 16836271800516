import { path, deepClone } from 'lodash/fp';
import { MEMBER_ROLE } from '../members/members-manage/constants';
import { compare } from '../common/dates';

export const getPersons = path('oms.admin.membership.persons');
export const getMembershipId = path('oms.admin.membership.id');

export const getSortedPersons = (data) => {
  const persons = getPersons(data) || [];
  const clonedData = [...persons];
  clonedData.sort((objectA, objectB) => {
    const personA = objectA;
    const personB = objectB;
    // eslint-disable-next-line no-nested-ternary
    const a = personA.role === MEMBER_ROLE.MEMBER
      ? 0
      : personA.role === MEMBER_ROLE.PARTNER
        ? 1
        : 2;
    // eslint-disable-next-line no-nested-ternary
    const b = personB.role === MEMBER_ROLE.MEMBER
      ? 0
      : personB.role === MEMBER_ROLE.PARTNER
        ? 1
        : 2;

    return a === b ? compare(personA.dob, personB.dob) : a - b;
  });
  return clonedData;
};
