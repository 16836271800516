import React from 'react';
import {
  Form, Icon, Input, Button, Checkbox, Typography, Alert,
} from 'antd';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';
import { Redirect, useLocation } from 'react-router';
import { path } from 'lodash/fp';
import routePaths from '../routing/routePaths';
import useAuth from './useAuth';
import {email, password, validateConfirmPassword} from '../common/validators';
import { useTheme } from '../theme';
import { PasswordInput } from '../common/atoms/PasswordInput';
import { handleSubmit } from '../common/util';

const { Title } = Typography;

const StyledContainer = styled(Flex)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;

const StyledForm = styled(Form)`
  width: 400px;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: ${(props) => props.theme.space[3]}px;
`;


const ChangePasswordPage = () => {
  const auth = useAuth();
  const {
    loading, error, changePassword, changePasswordForNewUser,
  } = auth;

  const location = useLocation();
  const theme = useTheme();
  const newPasswordRequired = path('state.newPasswordRequired', location);
  const [form] = Form.useForm();

  return (
    <StyledContainer width={1} justifyContent="center" alignItems="center">
      <StyledForm
        form={form}
        initialValues={{
          oldPassword: path('state.oldPassword', location),
        }}
        onFinish={newPasswordRequired ? ({ oldPassword, newPassword }) => {
          changePasswordForNewUser({ oldPassword, newPassword, username: location.state.username });
        } : changePassword}
      >
        <Title
          style={{
            textAlign: 'center',
            marginBottom: `${theme.space[5]}px`,
          }}
          level={2}
        >
          {' '}
                   Change Password
        </Title>

        {newPasswordRequired && (
        <Form.Item pb={3}>
          <Alert type="info" message="Before you can proceed we need you to enter your new password." />
        </Form.Item>
        )}

        <Form.Item
          name="oldPassword"
          rules={[{ required: true, message: 'Please input your email!' }, {
            validator: password,
          }]}
        >

          <PasswordInput
            placeholder="Enter your current password"
          />
        </Form.Item>
        <Form.Item
          name="newPassword"
          rules={[{ required: true, message: 'Please input your new password!' }, {
            validator: password,
          }]}
        >

          <PasswordInput placeholder="Enter your new password" />
        </Form.Item>

        <Form.Item
          name="newPasswordConfirm"
          rules={[
            { required: true, message: 'Please confirm your new password!' }, {
              validator: password,
            }, {
              validator: validateConfirmPassword(form, 'newPassword'),
            }]}
        >
          <PasswordInput placeholder="Confirm your new password" />
        </Form.Item>
        <Form.Item>
          {error && <StyledAlert message={error} type="error" showIcon />}

          <Box>
            <Button loading={loading} size="large" block type="primary" htmlType="submit">Change</Button>
          </Box>
        </Form.Item>
      </StyledForm>
    </StyledContainer>
  );
};


export default ChangePasswordPage;
