import { useEffect, useState } from 'react';
import useSlowHambsQueriesQuery from './useSlowHambsQueriesQuery';

export default () => {
  const [getSlowQueries, { data: slowQueries, loading: slowQueriesLoading, error: slowQueriesError }] = useSlowHambsQueriesQuery();
  const [filters, setFilters] = useState();
  const search = () => {
    getSlowQueries(filters);
  };


  return {
    state: {
      list: slowQueries,
      loading: slowQueriesLoading,
      error: slowQueriesError,
      filters,
    },
    actions: {
      search,
      setFilters,
    },
  };
};
