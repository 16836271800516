import React from 'react';
import { Card, Statistic } from 'antd';
import { Flex } from 'rebass';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  width: 100%;
  &&& {
    .ant-card-loading-content {
      width: 100%;
    }
    
    .ant-card-body {
    height: 100%;
    background: ${(props) => props.colors.background};
    }
    
    .ant-statistic {
      text-align: center;
    }
    
    .ant-statistic-content {
      color: ${(props) => props.colors.title};
    }
    .ant-statistic-title {
    color: ${(props) => props.colors.title}
    }
  }
`;

const StatCard = ({
  title = '', value, precision = 2, colors = {}, loading = false, ...props
}) => (
  <Flex justifyContent="center" {...props}>
    <StyledCard
      loading={loading}
      style={{ width: '100%' }}
      colors={colors}
      bodyStyle={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Statistic title={title} value={value || 'Not Available'} precision={precision} />
    </StyledCard>
  </Flex>

);

export default StatCard;
