import React, { createContext, useContext } from 'react';
import { notification } from 'antd';
import { createGlobalStyle } from 'styled-components';

const NotificationContext = createContext();

notification.config({
  placement: 'topRight',
  top: 50,
  duration: 10,
});

const NotificationStyles = createGlobalStyle`
  .ant-notification-notice-message {
    font-size: 14px;
  }
`;

const NotificationProvider = ({ children, ...props }) => (
  <NotificationContext.Provider
    value={notification}
    {...props}
  >
    <>
      <NotificationStyles />
      {children}
    </>

  </NotificationContext.Provider>
);
const useNotification = () => useContext(NotificationContext);


export { NotificationContext, NotificationProvider, useNotification };
