import { identity } from 'lodash';
import { pipe } from 'lodash/fp';
import { containsCaseInsensitive } from '../../common/util';
import { createFilter, withCheck } from '../../common/filters';
import {ALLOWED_NUMBER_OPERATORS} from "./constants";

const parseResponseTimeComparator = (symbol, compareValue) => {
  const comparators = {
    '<': (recordValue) => recordValue < compareValue,
    '<=': (recordValue) => recordValue <= compareValue,
    '>': (recordValue) => recordValue > compareValue,
    '>=': (recordValue) => recordValue >= compareValue,
    '=': (recordValue) => recordValue === compareValue,
  };

  Object.assign(comparators, {
    lt: comparators['<'],
    lte: comparators['<='],
    eq: comparators['='],
    gt: comparators['>'],
    gte: comparators['>='],
  });

  return comparators[symbol] || comparators.eq;
};

const parseOperator = (value = '') => {
  let matchedOperator = '';
  value = value.trim();
  for (let allowedOperator of ALLOWED_NUMBER_OPERATORS) {
    if (value.startsWith(allowedOperator) && allowedOperator.length > matchedOperator.length) {
      matchedOperator = allowedOperator;
    }
  }

  return matchedOperator;
};

const filterParsers = {
  responseTime: (value) => {
    value = value.trim();
    const compareSymbol = parseOperator(value);
    const compareValue = Number(compareSymbol ? value.slice(compareSymbol.length).trim() : value);


    return (recordValue) => {
      recordValue /= 1000;
      recordValue = recordValue.toFixed(2);
      recordValue = Number(recordValue);
      return parseResponseTimeComparator(compareSymbol, compareValue)(recordValue);
    };
  },

  url: (value) => (recordValue) => containsCaseInsensitive(recordValue, value),

  statusCode: (value = []) => {
    const getStatusCodeComparator = (statusCodeFilterValue) => {
      if (statusCodeFilterValue === 'errors') {
        return recordValue => recordValue >= 400;
      }

      const asNumber = Number(statusCodeFilterValue);
      if (Number.isNaN(asNumber)) {
        const statusCodeGroup = Number(statusCodeFilterValue[0]) * 100;
        return (recordValue) => recordValue >= statusCodeGroup && recordValue < statusCodeGroup + 100;
      }


      return (recordValue) => recordValue === asNumber;
    };
    return (recordValue) => value.reduce((acc, statusCode) => acc
          || getStatusCodeComparator(statusCode)(recordValue), false);
  },
  method: (value = []) => (recordValue) => value.includes(recordValue),
};

export const filter = pipe(
  withCheck,
  createFilter,
)(filterParsers);
