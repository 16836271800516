import React, { useContext, useMemo } from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { path as lodashPath } from 'lodash/fp';
import { AuthContext } from '../auth/AuthContext';
import routePaths from './routePaths';
import Spin from '../common/atoms/Spinner';
import routeAccess from './routeAccess';
import useAuth from '../auth/useAuth';

const PrivateRoute = ({ children, location, ...props }) => {
  const {
    isLoggedIn, gettingUser, canAccess, userData,
  } = useAuth();

  if (process.env.NODE_ENV !== 'production') {
    console.log('USER DATA', userData);
  }

  const isAccessible = canAccess(routeAccess[location.pathname]);
  // eslint-disable-next-line eqeqeq
  if (gettingUser || gettingUser == null) {
    return <Spin pageSpin />;
  }

  return (
    <Route
      {...props}
      render={() => (isLoggedIn && isAccessible ? children : <Redirect to={{ pathname: routePaths.login }} />)}
    />
  );
};

export default PrivateRoute;
