import React from 'react';
import {
  Button, Input, Form,
} from 'antd';
import styled from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';

const MemberIdInput = styled(Input)`
  margin-right: ${(props) => props.theme.space[2]}px;
`;

const StyledForm = styled(Form)`
  display: flex;
`;


const UserFilters = ({
  loading, onSearch, ...props
}) => (
  <StyledForm
    layout="inline"
    {...props}
    onFinish={(values) => {
      onSearch(values);
    }}
  >
    <Form.Item name="name">
      <MemberIdInput
        disabled={loading}
        type="text"
        placeholder="name"
        suffix={<SearchOutlined type="search" />}
      />
    </Form.Item>
    <Form.Item name="email">
      <MemberIdInput
        disabled={loading}
        type="text"
        placeholder="email"
        suffix={<SearchOutlined type="search" />}
      />
    </Form.Item>
    <Form.Item>
      <Button loading={loading} htmlType="submit" type="primary">Search</Button>
    </Form.Item>
  </StyledForm>
);

export default UserFilters;
