import React from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import { formatDateTime } from '../../common/dates';
import StatusCode from '../StatusCode';
import Method from '../Method';

const StyledTable = styled(Table)`
  .ant-table-tbody {
  background: ${(props) => props.theme.colors.white};
  }
`;

const SlowQueriesTable = ({
  loading, logs, pagination, onChange,
}) => {
  const columns = [
    {
      title: 'Time',
      dataIndex: 'timestamp',
      width: '15%',
      render: (date) => formatDateTime(date),
    },
    {
      title: 'Response time(s)',
      dataIndex: 'responseTime',
      width: '10%',
      render: (value) => (Number(value) / 1000).toFixed(2),
    },
    {
      title: 'Endpoint',
      dataIndex: 'url',
      width: '40%',
    },
    {
      title: 'Status Code',
      dataIndex: 'statusCode',
      width: '20%',
      render: (value) => <StatusCode value={value} />,
    },
    {
      title: 'Method',
      dataIndex: 'method',
      width: '15%',
      render: (value) => <Method value={value} />,
    },
  ];

  return (
    <StyledTable
      rowKey={(query) => query.url}
      loading={loading}
      onChange={onChange}
      pagination={pagination}
      columns={columns}
      dataSource={logs}
    />
  );
};

export default SlowQueriesTable;
