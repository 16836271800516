import { useMutation } from '@apollo/client';
import AdminSetHambsPasswordMutation from '../../../queries/adminSetHambsPasswordMutation.graphql';

export default () => {
  const [mutation, ...rest] = useMutation(AdminSetHambsPasswordMutation);

  const resetPassword = (membershipId) => mutation({
    variables: {
      input: {
        membershipId,
        generate: true,
      },
    },
  });

  const syncPassword = (membershipId) => mutation({
    variables: {
      input: {
        membershipId,
        generate: false,
      },
    },

  });

  return [resetPassword, syncPassword, ...rest];
};
