import eligibility from './eligibility';
import yourDetails from './yourDetails';
import familyDetails from './familyDetails';
import medicare from './medicare';
import additionalDetails from './additionalDetails';
import payments from './payments';
import quoteInput from './quoteInput';


export default [{
  id: 'quoteInput',
  handler: quoteInput,
}, {
  id: 'eligibility',
  handler: eligibility,
}, {
  id: 'yourDetails',
  handler: yourDetails,
}, {
  id: 'familyDetails',
  handler: familyDetails,
}, {
  id: 'medicare',
  handler: medicare,
}, {
  id: 'additionalDetails',
  handler: additionalDetails,
}, {
  id: 'payments',
  handler: payments,
}];
