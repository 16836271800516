import { curry, path } from 'lodash/fp';


export const onlyOnce = (fn) => {
  let executed = false;

  return (...args) => {
    if (!executed) {
      executed = true;
      return fn(...args);
    }

    return null;
  };
};

export const noop = () => {};

export const padLeft = (char, maxLength) => (string) => {
  const padLength = maxLength - string.length;
  return padLength > 0
    ? Array.from({ length: padLength }, () => char).join('') + string
    : string;
};


export const capitalize = (word) => (word ? word[0].toUpperCase() + word.slice(1).toLowerCase() : '');

export const base64ToBlob = (str, type = 'octet/stream') => {
  const binaryString = atob(str);
  const arr = new Uint8Array(new ArrayBuffer(binaryString.length));

  for (let i = 0; i < binaryString.length; i += 1) {
    arr[i] = binaryString.charCodeAt(i);
  }

  return new Blob([arr], { type });
};

export const dropProp = (name, obj) => {
  if (!obj) {
    return obj;
  }
  const newObj = {
    ...obj,
  };
  delete newObj[name];
  return newObj;
};

export const dropProps = (props = [], obj) => props.reduce(
  (acc, propToDrop) => dropProp(propToDrop, acc),
  obj,
);

export const dropEmpties = (object) => Object.entries(object || {})
  .filter(([, value]) => value != null)
  .reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value,
    }),
    {},
  );

export const range = (from, to) => Array.from({
  length: to - from + 1,
}).map((_, index) => from + index);

export const swap = curry(
  (swapElement, idName, list) => list.map(
    (item) => (item[idName] === swapElement[idName] ? swapElement : item),
  ),
);

export const update = curry((updateElement, idName = 'id', list) => list.map((item) => (item[idName] === updateElement[idName] ? {
  ...item,
  ...updateElement,
} : item)));
export const chainedPath = (basePath) => (data) => {
  const baseData = path(basePath, data);
  return (additionalPath) => (additionalPath ? path(additionalPath, baseData) : baseData);
};

// use this function instead of one from lodash/fp as
// the stupid thing will return null (not empty array)
// for expressions like this pathOr([], 'oms.membership.persons', data);
export const pathOr = curry((or, pathToResult, data) => {
  const result = path(pathToResult)(data);
  return result || or;
});

export const parseQueryParams = (queryString) => queryString
  .split('&')
  .map((param) => param.split('='))
  .reduce((acc, [key, value]) => {
    acc[key] = decodeURIComponent(value);
    return acc;
  }, {});

export const parseStateCodeFromAddress = (addressString = '') => {
  const addressParts = addressString.split(' ');
  return addressParts[addressParts.length - 1];
};

const uint8ArrayToText = (array = []) => {
  let text = '';
  array.forEach((byte) => {
    text += String.fromCharCode(byte);
  });
  return text;
};

export const readTextStream = async (readableStream) => {
  const reader = readableStream.getReader();
  let text = '';
  let done;
  do {
    // eslint-disable-next-line no-await-in-loop
    const chunkInfo = await reader.read();
    done = chunkInfo.done;
    text += uint8ArrayToText(chunkInfo.value);
  } while (!done);

  return text;
};

export const formSafeValues = (values) => Object.entries(values).reduce((acc, [name, value]) => ({
  ...acc,
  [name]: value === null ? '' : value,
}), {});

export const waterfall = async (listOfFunctions) => {
  const asyncIterable = {
    [Symbol.asyncIterator]() {
      return {
        i: 0,
        value: undefined,
        async next() {
          if (this.i < listOfFunctions.length) {
            this.value = await listOfFunctions[this.i](this.value);
            this.i += 1;
            return {
              value: this.value,
              done: false,
            };
          }

          return { done: true };
        },
      };
    },
  };

  const results = [];

  // eslint-disable-next-line no-restricted-syntax
  for await (const result of asyncIterable) {
    results.push(result);
  }

  return results;
};

export const handleSubmit = (form, onSubmit) => (event) => {
  event.preventDefault();
  form.validateFields((err, values) => {
    if (!err) {
      onSubmit(values);
    }
  });
};

export const getMemberFullName = (member) => `${member.firstName} ${member.lastName}`;

export const EMPTY_OBJECT = {};
export const EMPTY_ARRAY = [];

export const equalsCaseInsensitive = (stringA, stringB) => (stringA || '').toLowerCase() === (stringB || '').toLowerCase();

export const containsCaseInsensitive = (stringA, stringB) => (stringA || '').toLowerCase().startsWith((stringB || '').toLowerCase());

export const downloadTextFile = (filename, text) => {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();
  document.body.removeChild(element);
};
