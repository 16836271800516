import { useEffect, useMemo, useState } from 'react';
import { Form } from 'antd';
import createPersistedState from 'use-persisted-state';
import moment from 'moment';
import { isEmpty } from 'lodash';
import useHambsLogsQuery from './useHambsLogsQuery';
import { date } from '../../common/dates';
import { clientSideFilter, filter } from './filters';
import { createClientSideFiltering } from '../../common/hooks/useClientSideFiltering';

const useClientSideFiltering = createClientSideFiltering('hambs-logs-filters');


export default () => {
  const [getLogs, {
    data, lots, currentLotIndex, loading, error,
  }] = useHambsLogsQuery();
  const clientSideFiltering = useClientSideFiltering({
    data,
    getData: getLogs,
    filter,
  });


  return {
    state: {
      ...clientSideFiltering.state,
      loading,
      error,
      lots,
      currentLotIndex,
    },
    actions: {
      ...clientSideFiltering.actions,
      changeLot: (index) => {
        clientSideFiltering.actions.search(clientSideFiltering.state.filters, index);
      },
    },
  };
};
