import { path } from 'lodash/fp';

import MafLogsFull from '../../queries/mafLogsFull.graphql';
import usePagedQuery from '../../common/hooks/usePagedQuery';
import { NETWORK_STATUS } from '../../common/constants';

const extractLogs = path('oms.admin.logs.maf.logs');
const getNext = path('oms.admin.logs.maf.next');
export default () => {
  const [getMafLogs, { data, refetch, networkStatus, ...rest }] = usePagedQuery(
    MafLogsFull,
    {
      fetchPolicy: 'network-only',
      nextLot: getNext,
      notifyOnNetworkStatusChange: true,
      queryInput: filters => ({
        startTime: filters.date.start,
        endTime: filters.date.end,
        membershipId: filters.membershipId || undefined,
        reference: filters.reference || undefined,
      }),
      onCompleted: (data, { variables } = {}) => {
        const nextToken = getNext(data);
        if (!extractLogs(data)?.length && nextToken) {
          refetch(currentVariables => {
            return {
              input: {
                ...currentVariables?.input,
                next: nextToken,
              },
            };
          });
        }
      },
    }
  );

  return [
    getMafLogs,
    {
      refetch,
      data: extractLogs(data),
      loading: [
        NETWORK_STATUS.INITIAL_LOADING,
        NETWORK_STATUS.REFETCHING,
      ].includes(networkStatus),
      ...rest,
    },
  ];
};
