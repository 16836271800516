import { pipe, reject, whereEq } from 'lodash/fp';
import { cloneDeep } from 'lodash';
import MembershipPersons from '../../queries/membershipPersons.graphql';

const getCurrentDetails = (store, input) => {
  return cloneDeep(store.readQuery({
    query: MembershipPersons,
    variables: {
      input,
    },
  }));
}

export const updatePersonStore = (store, {id, updateDetails, membershipId }) => {
  store.modify({
    id: store.identify({
      id,
      __typename:"Person",
      ...updateDetails
    }),
    fields: Object.entries(updateDetails).reduce((acc, [propName, propValue]) => {
      acc[propName] = () => {
        return propValue;
      };
      return acc;
    }, {}),
    broadcast: false,
  });
};

export const removeAllPersonsFromStore = (store, membershipId) => {
  const currentDetails = cloneDeep(store.readQuery({
    query: MembershipPersons,
    variables: {
      input: {
        id: membershipId,
      },
    },
  }));

  currentDetails.oms.admin.membership.persons = [];
  store.writeQuery({
    query: MembershipPersons,
    data: currentDetails,
    variables: {
      input: {
        id: membershipId,
      },
    },
  });
};
