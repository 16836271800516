import React from 'react';
import FieldsView from './FieldsView';
import { formatDate } from '../../../common/dates';

const formatAddress = (value) => [
  value.line1,
  value.suburb,
  value.state,
  value.postcode,
  value.country,
].filter(Boolean).join(' ');

export default {
  title: 'Your details',
  customRender: ({ data, allData }) => (
    <>
      <FieldsView
        title="Private health details"
        fields={{
          startCoverDate: {
            value: formatDate,
          },
          isFamilyPackage: false,
        }}
        data={data.privateHealthDetails}
        allData={allData}
        divider
      />

      <FieldsView

        divider
        title="Personal details"
        data={data.personalDetails}
        allData={allData}
      />

      <FieldsView
        fields={{
          isPostalAddressDifferent: false,
          residentialAddress: {
            value: formatAddress,
          },
          postalAddress: {
            value: formatAddress,
          },
        }}
        title="Contact details"
        data={data.contactDetails}
        allData={allData}
      />
    </>
  ),
};
