import React from 'react';
import { Tag } from 'antd';
import { formatDateTime } from '../../common/dates';
import LogsTable from '../../common/organisms/LogsTable';


const ConversionLogsTable = ({ logs, ...props }) => {
  const columns = [
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      width: 'auto',
      render: formatDateTime,
    },
    {
      title: 'Membership ID',
      dataIndex: 'membershipId',
      width: 'auto',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 'auto',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 'auto',
      render: (value = '') => {
        const color = {
          OK: 'green',
          UNAUTHORIZED: 'red',
          ECONNRESET: 'red',
        }[value.toUpperCase()];
        return <Tag color={color}>{value.toUpperCase()}</Tag>;
      },
    },
  ];

  return (
    <>
      <LogsTable
        scroll={{
          x: 650,
        }}
        rowKey={(record) => record.timestamp + record.membershipId}
        columns={columns}
        dataSource={logs}
        {...props}
      />
    </>
  );
};

export default ConversionLogsTable;
