import React from 'react';
import { Button } from 'antd';
import { Flex, Box } from 'rebass';
import { path } from 'lodash/fp';
import useSlowHambsQueries from './useSlowHambsQueries';
import SlowQueriesTable from './SlowQueriesTable';
import DateRangeFilter from '../../common/molecules/DateRangeFilter';


const SlowQueries = () => {
  const slowQueries = useSlowHambsQueries();
  return (
    <>
      <Flex my={3} justifyContent="flex-start">
        <Box mr={2}>
          <DateRangeFilter
            disabled={slowQueries.state.loading}
            onChange={(value) => {
              slowQueries.actions.setFilters({
                date: value,
              });
            }}
            value={path('date')(slowQueries.state.filters)}
          />
        </Box>
        <Button
          disabled={slowQueries.state.loading}
          type="primary"
          ml={2}
          onClick={slowQueries.actions.search}
        >
                        Search
        </Button>

      </Flex>
      <SlowQueriesTable
        loading={slowQueries.state.loading}
        logs={slowQueries.state.list}
      />
    </>
  );
};

export default SlowQueries;
