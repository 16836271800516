import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const StyledSpin = styled(Spin).withConfig({
  shouldForwardProp: prop => !['pageSpin'].includes((prop))
})`
   &&& {
     ${(props) => (props.pageSpin ? `
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%)
   ` : '')}
   }
`;

export default ({
  children, pageSpin, size, ...props
}) => (<StyledSpin size={size || (pageSpin ? 'large' : undefined)} pageSpin={pageSpin} {...props}>{children}</StyledSpin>);
