import React, { useEffect, useState } from 'react';
import { AutoComplete, Input } from 'antd';
import { Box } from 'rebass';
import { EMPTY_ARRAY } from '../common/util';

const SearchStats = ({ allStats = EMPTY_ARRAY, onChange, ...props }) => {
  const [value, setValue] = useState('');

  return (
    <Box {...props}>
      <Input
        placeholder="Search stat"
        onChange={evt => {
            onChange(evt.target.value);
        }}
      />
    </Box>

  );
};

export default SearchStats;
