import { useEffect, useMemo } from 'react';
import { Form } from 'antd';
import createPersistedState from 'use-persisted-state';
import { date } from '../../common/dates';
import moment from 'moment';

export const createClientSideFiltering = (key) => {
    const usePersistedState = createPersistedState(key);

    const useFilters = () => {
        const [state, setState] = usePersistedState();
        const transformedState = {
            ...state,
            date: {
                start: (state?.date?.start && moment(state.date.start)) || date().hours(0).minutes(0).seconds(1),
                end: (state?.date?.end && moment(state.date.end)) || date(),
            }
        };


        return [transformedState, setState]
    };


    return ({ data, getData, filter, initialLoad = false }) => {
        const [filtersForm] = Form.useForm();
        const [filters, setFilters] = useFilters();

        const search = (newFilters, ...rest) => {
            filtersForm.setFieldsValue(newFilters);
            setFilters(newFilters);
            getData(newFilters, ...rest);
        };

        useEffect(() => {
            if (initialLoad) {
                search(filters);
            } else {
                filtersForm.setFieldsValue(filters);
            }
        }, []);


        const list = useMemo(() => {
            if (!data || !filters) {
                return data;
            }

            return filter(data, filters);
        }, [data, filters]);

        return {
            state: {
                list,
                filters,
                filtersForm,
            },
            actions: {
                search,
            },
        };
    };
}
