import {  NetworkStatus } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import stats from './stats-config';
import useMembershipStatsQuery from './useMembershipStatsQuery';
import { EMPTY_OBJECT } from '../common/util';
import { useTheme } from '../theme';
import { useNotification } from '../common/providers/Notification';


export default () => {
  const softRefreshInterval = (process.env.DHF_STATS_AUTO_RELOAD_INTERVAL ?? 30) * 1000;
  const {
    data = EMPTY_OBJECT,
    error = EMPTY_OBJECT,
    called,
    refetch,
    networkStatus,
    client,
    startPolling,
    stopPolling,
  } = useMembershipStatsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        reloadCache: false
      }
    }
  });

  const [prevReloadingCache, setPrevReloadingCache] = useState(data?.reloadingCache);
  const [searchStatText, setSearchStatText] = useState('');
  const theme = useTheme();
  const notification = useNotification();

  const membershipStats = useMemo(() => Object
    .entries(stats)
    .map(([statKey, statMetadata]) => {
      const value = data && data[statKey];
      return {
        ...statMetadata,
        id: statKey,
        value: typeof statMetadata.formatValue === 'function' ? statMetadata.formatValue(value) : value,
        colors: statMetadata.colors && statMetadata.colors(theme.colors),
      };
    })
    .filter((stat) => (!searchStatText || stat.title
      .toLowerCase()
      .includes(searchStatText.toLowerCase()))), [data, searchStatText]);

  useEffect(() => {
    startPolling(softRefreshInterval);
    return () => {
      stopPolling();
    };
  }, []);

  useEffect(() => {
    if (error === EMPTY_OBJECT) {
      return;
    }

    notification.error({
      message: 'Unexpected error!',
      description: 'Please refresh the page in order to get the stats',
    });

    console.error(error);
  }, [error]);

  useEffect(()=> {
    if (prevReloadingCache && data?.reloadingCache === false) {
      notification.info({
        message: 'The stats reload has completed.',
        description: 'You are now seeing the most recent stats.'
      });

    }
    setPrevReloadingCache(data?.reloadingCache);
  }, [data?.reloadingCache])

  const reloadStatsSoft = () => {
    return refetch({
      input: {
        reloadCache: false
      }
    });
  };

  const reloadStatsHard = () => {
    stopPolling();
    return refetch({
      input: {
        reloadCache: true
      }
    }).then(() => {
      return reloadStatsSoft().then(() => {
        startPolling(softRefreshInterval);
      });
    });
  };

  return {
    membershipStats: {
      searchStats: setSearchStatText,
      reloadStatsSoft,
      reloadStatsHard,
      data: membershipStats,
      loading: [
        NetworkStatus.loading,
        NetworkStatus.setVariables,
        NetworkStatus.refetch,
        NetworkStatus.poll].includes(networkStatus),
      error,
      reloadingCache: data?.reloadingCache,
      softRefreshInterval
    },
  };
};
