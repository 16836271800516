import { useLazyQuery } from '@apollo/client';
import {  useState } from 'react';
import { pipe, path } from 'lodash/fp';
import {
  getMembershipId, getSortedPersons,
} from '../../../selectors';
import getApplicableActions from '../actionsApplicability';

import MembershipPersons from '../../../queries/membershipPersons.graphql';
import {MFA_METHODS} from "../constants";

export default () => {
  const [queryInput, setQueryInput] = useState({});
  const [getMembers, { data, ...rest }] = useLazyQuery(MembershipPersons, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });


  const members = pipe(
    getSortedPersons,
    (persons) => persons?.map((person) => ({
      ...person,
      personId: person.id,
      membershipId: getMembershipId(data),
      actions: getApplicableActions(person, persons),
      highlighted: queryInput.email === person.email,
      hasMfa: person.MFASettings?.length,
      hasSmsMfa: person.MFASettings?.includes(MFA_METHODS.SMS_MFA)
    })),
  )(data);

  return [({ memberId, email }) => {
    const input = email ? {
      email,
    } : {
      id: memberId,
    };
    setQueryInput(input);
    getMembers({
      variables: {
        input,
      },
    });
  }, {
    data: {
      members,
      membershipId: path('0.membershipId')(members) || getMembershipId(data),
    },
    ...rest,
  }];
};
