import { useApolloClient, useMutation } from '@apollo/client';
import UninviteMember from '../../../queries/uninviteMember.graphql';
import { updatePersonStore } from '../storeUpdaters';
import { UNKNOWN_ID } from '../constants';

export default () => {
  const [mutation, ...rest] = useMutation(UninviteMember);
  const client = useApolloClient();

  const uninviteMember = (membershipId, person) => mutation({
    variables: {
      input: {
        id: (person.id && person.id !== UNKNOWN_ID) ? person.id : person.email || UNKNOWN_ID,
      },
    },
    update(store, { data: { adminUninvitePerson } }) {
      // TODO: remove this once API is fixed so it returns updated person
      if (adminUninvitePerson.success && person.id && person.id !== UNKNOWN_ID) {
        updatePersonStore(store, {
          id: person.id,
          updateDetails: {
            ...person,
            onlineAccess: false,
            emailVerified: false,
          },
          membershipId,
        });
      }
    },
  });

  return [uninviteMember, ...rest];
};
