export const USER_GROUPS = {
  SERVICE_DESK: {
    label: 'Service Desk',
    id: 'DHF_MemberServicesUsers',
  },
  SYSTEM: {
    label: 'System',
    id: 'DHF_ITSystemUsers',
  },
  ADMIN: {
    label: 'Admin',
    id: 'DHF_AdminToolsAdministrators',
  },
};

export const FORM_MODES = {
  ADD: 'add',
  EDIT: 'edit',
};

export const NETWORK_STATUS = {
  INITIAL_LOADING: 1,
  POLLING: 6,
  REFETCHING: 4,
};


export const USERS_GROUP_LIST = Object.values(USER_GROUPS);
