import { useState } from 'react';
// eslint-disable-next-line import/named
import { createClientSideFiltering } from '../../common/hooks/useClientSideFiltering';
import filter from './filters';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import useMafLogsQuery from './useMafLogsQuery';
import useToggle from '../../common/hooks/useToggle';

const useClientSideFiltering = createClientSideFiltering('maf-logs-filters');

export default () => {
  const logs = useMafLogsQuery();
  const [
    getLogs,
    { data, error: logsError, loading: logsLoading, lots, currentLotIndex },
  ] = logs;
  const [
    fullDetailsModalVisible,
    _,
    showFullDetailsModal,
    hideFullDetailsModal,
  ] = useToggle();
  const [selectedLog, selectLog] = useState();
  const filtering = useClientSideFiltering({
    data,
    getData: getLogs,
    filter,
  });

  const showFullDetails = log => {
    selectLog(log);
    showFullDetailsModal();
  };

  return {
    state: {
      ...data,
      loading: logsLoading,
      error: logsError,
      selectedLog,
      fullDetailsModalVisible,
      lots,
      currentLotIndex,
      ...filtering.state,
    },
    actions: {
      showFullDetails,
      closeFullDetails: hideFullDetailsModal,
      changeLot: index => {
        filtering.actions.search(filtering.state.filters, index);
      },
      ...filtering.actions,
    },
  };
};
