import React from 'react';
import {
  Form, Input, Select, Tag,
} from 'antd';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { space, layout } from 'styled-system';
import DateRangeFilter from '../../common/molecules/DateRangeFilter';
import Button from '../../common/atoms/Button';
import StatusCodeFilter from '../../common/molecules/StatusCodeFilter';

const StyledForm = styled(Form)`
   width: 100%;
`;

const FormItem = styled(Form.Item)(
  space,
  layout,
);


const methodColors = {
  PUT: 'blue',
  POST: 'orange',
  GET: 'green',
  DELETE: 'red',
};

const HambsLogsFilters = ({
  form, loading, onSearch,
}) => (
  <StyledForm
    form={form}
    name="hambs-logs-filters-form"
    onFinish={(values) => {
      onSearch(values);
    }}
  >
    <Flex>
      <FormItem width={1 / 4} mr={2} name="membershipId">
        <Input
          placeholder="Membership"
          type="number"
          disabled={loading}
        />
      </FormItem>
      <FormItem
        mr={2}
        width={1 / 4}
        name="requestId"
      >
        <Input
          placeholder="Request ID"
          disabled={loading}
        />
      </FormItem>
      <FormItem
        width={1 / 4}
        mr={2}
        name="responseTime"
      >
        <Input
          type="text"
          placeholder="Response time eg. < 2.5 or just 2.5"
          disabled={loading}
        />
      </FormItem>

      <FormItem
        width={1 / 4}
        name="url"
      >
        <Input
          placeholder="Endpoint"
          disabled={loading}
        />
      </FormItem>
    </Flex>
    <Flex />

    <Flex>
      <FormItem mr={2} width={3 / 10} name="method">
        <Select
          disabled={loading}
          placeholder="Method"
          mode="multiple"
          tagRender={({ value, label, ...props }) => <Tag color={methodColors[value]}>{value}</Tag>}
        >
          <Select.Option value="PUT">
            <Tag color={methodColors.PUT}>PUT</Tag>
          </Select.Option>
          <Select.Option value="POST">
            <Tag color={methodColors.POST}>POST</Tag>
          </Select.Option>

          <Select.Option value="GET">
            <Tag color={methodColors.GET}>GET</Tag>
          </Select.Option>
          <Select.Option value="DELETE">
            <Tag color={methodColors.DELETE}>DELETE</Tag>
          </Select.Option>
        </Select>
      </FormItem>

      <FormItem mr={2} width={3 / 10} name="statusCode">
        <StatusCodeFilter disabled={loading} />
      </FormItem>

      <FormItem mr={2} width={3 / 10} name="date">
        <DateRangeFilter
          disabled={loading}
        />
      </FormItem>

      <Button
        width={1 / 10}
        htmlType="submit"
        type="primary"
        disabled={loading}
      >
        Search
      </Button>
    </Flex>


  </StyledForm>
);

export default HambsLogsFilters;
