import React from 'react';
import { Tabs } from 'antd';
import {
  AuditOutlined, ContainerOutlined, ZoomInOutlined, ContactsOutlined,
} from '@ant-design/icons';
import SlowQueries from './slow-queries/SlowQueries';
import HambsLogs from './hambs-logs/HambsLogs';
import MedicareRebates from './medicare-rebates/MedicareRebates';
import MafLogs from './maf-logs/MafLogs';

const Logs = () => (
  <Tabs items={[{
    key: 'hambs-logs',
    label: <span>
      <ContactsOutlined />
      Hambs
    </span>,
    children: <HambsLogs />
  }, {
    key: 'slow-queries',
    label: <span>
      <ZoomInOutlined />
      HAMBS Slow Queries
    </span>,
    children: <SlowQueries />
  }, {
    key: 'medicare-rebates',
    label: <span>
      <AuditOutlined />
      Medicare Rebates
    </span>,
    children: <MedicareRebates />
  }, {
    key: 'maf-logs',
    label: <span>
      <ContainerOutlined />
      MAF
    </span>,
    children:  <MafLogs />
  }]} />
);

export default Logs;
