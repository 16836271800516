import React from 'react';
import ReactJson from 'react-json-view';
import { formatDateTime } from '../../common/dates';
import StatusCode from '../StatusCode';
import Method from '../Method';
import LogsTable from '../../common/organisms/LogsTable';

const formatResponseTime = (value) => (Number(value) / 1000).toFixed(2);

export const hambsLogsColumns = [
  {
    title: 'Membership',
    dataIndex: 'membershipId',
    width: '10%',
    fixed: 'left',
    sort: 'number',
  }, {
    title: 'Request ID',
    dataIndex: 'requestId',
    width: '15%',
    fixed: 'left',
    sort: 'string',
  },
  {
    title: 'Time',
    dataIndex: 'timestamp',
    width: '15%',
    sort: 'date',
    render: formatDateTime,
    formatCsv: formatDateTime,
  },
  {
    title: 'Response time(s)',
    dataIndex: 'responseTime',
    width: '10%',
    sort: 'number',
    render: formatResponseTime,
    formatCsv: formatResponseTime,
  },
  {
    title: 'Endpoint',
    dataIndex: 'url',
    width: '30%',
    sort: 'string',
  },
  {
    title: 'Status Code',
    dataIndex: 'statusCode',
    width: '15%',
    sort: 'number',
    render: (value) => <StatusCode value={value} />,
  },
  {
    title: 'Method',
    dataIndex: 'method',
    width: '10%',
    sort: 'string',
    render: (value) => <Method value={value} />,
  },
  {
    title: 'Payload',
    dataIndex: 'body',
    hide: true,
  },
];

const HambsLogsTable = ({
  logs, ...props
}) => (
  <LogsTable
    scroll={{
      x: 1300,
      y: 490,
    }}

    columns={hambsLogsColumns.filter((column) => !column.hide)}
    dataSource={logs}
    expandable={{
      expandedRowRender: (record) => (
        <ReactJson
          displayDataTypes={false}
          displayObjectSize={false}
          src={typeof record.body === 'string' ? JSON.parse(record.body) : record.body}
        />
      ),
      rowExpandable: (record) => record.body,
    }}
    {...props}
  />
);

export default HambsLogsTable;
