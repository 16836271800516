import React from 'react';
import {
  Form, Input,
} from 'antd';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { space, layout } from 'styled-system';
import DateRangeFilter from '../../common/molecules/DateRangeFilter';
import Button from '../../common/atoms/Button';

const StyledForm = styled(Form)`
   width: 100%;
`;

const FormItem = styled(Form.Item)(
  space,
  layout,
);

const MedicareRebatesFilters = ({
  form, loading, onSearch,
}) => (
  <StyledForm
    form={form}
    name="medicare-rebates-filters-form"
    onFinish={(values) => {
      onSearch(values);
    }}
  >
    <Flex>
      <FormItem width={1 / 4} mr={2} name="id">
        <Input
          placeholder="SugarCRM Lead ID"
          type="string"
          disabled={loading}
        />
      </FormItem>
      <FormItem
        mr={2}
        width={1 / 4}
        name="reference"
      >
        <Input
          placeholder="SugarCRM Reference"
          disabled={loading}
        />
      </FormItem>
      <FormItem
        width={1 / 4}
        mr={2}
        name="cardNumber"
      >
        <Input
          type="text"
          placeholder="Medicare Card Number"
          disabled={loading}
        />
      </FormItem>
    </Flex>
    <Flex />
    <Flex>
      <FormItem mr={2} width={3 / 10} name="date">
        <DateRangeFilter
          disabled={loading}
        />
      </FormItem>
      <Button
        width={1 / 10}
        htmlType="submit"
        type="primary"
        disabled={loading}
      >
        Search
      </Button>
    </Flex>
  </StyledForm>
);

export default MedicareRebatesFilters;
