import { pipe } from 'lodash/fp';
import { createFilter, withCheck } from '../../common/filters';


const filterParsers = {
  status: (value = []) => (recordValue) => value.includes(recordValue),
};

export default pipe(
  withCheck,
  createFilter,
)(filterParsers);
