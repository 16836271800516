import React from 'react';
import {
  Button, Input, Form,
} from 'antd';
import { Box } from 'rebass';
import styled from 'styled-components';
import { string } from 'yup';
import { SearchOutlined } from '@ant-design/icons';
import { handleSubmit } from '../../common/util';

const MemberIdInput = styled(Input)`
  margin-right: ${(props) => props.theme.space[2]}px;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row;
`;

const MemberFilters = ({
  form, loading, onSearch, ...props
}) => (
  <Box {...props}>
    <StyledForm
      layout="inline"
      onFinish={(value) => {
        const payload = string().email().isValidSync(value.searchText) ? {
          email: (value.searchText || '').trim(),
        } : {
          memberId: (value.searchText || '').trim(),
        };

        onSearch(payload);
      }}
    >
      <Form.Item name="searchText">
        <MemberIdInput
          disabled={loading}
          type="text"
          placeholder="Member ID or email"
          suffix={<SearchOutlined type="search" />}
        />
      </Form.Item>

      <Form.Item>
        <Button loading={loading} htmlType="submit" type="primary">Search</Button>
      </Form.Item>
    </StyledForm>
  </Box>
);

export default MemberFilters;
