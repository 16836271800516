import { useEffect } from 'react';
import useConversionLogsQuery from './useConversionLogsQuery';
import { createClientSideFiltering } from '../../common/hooks/useClientSideFiltering';
import filters from './filters';

const useClientSideFiltering = createClientSideFiltering('conversion-logs');

export default () => {
  const [getLogs, { data, error, loading, currentLotIndex, lots, nextRef }] = useConversionLogsQuery();
  const { state, actions } = useClientSideFiltering({
    data,
    getData: getLogs,
    filter: filters,
  });

  useEffect(() => {
    state.filtersForm.setFieldsValue({
      searchText: state.filters.membershipId || state.filters.email,
    });
  }, []);


  return {
    state: {
      logs: state.list,
      filters: state.filters,
      filtersForm: state.filtersForm,
      error,
      loading,
      currentLotIndex,
      lots
    },
    actions: {
      ...actions,
      changeLot: index => {
        getLogs(state.filters, index);
      }
    },
  };
};
