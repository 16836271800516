import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';
import {Button, Tooltip} from 'antd';
import { ReloadOutlined, SyncOutlined } from '@ant-design/icons';
import StatCard from './StatCard';
import useReport from './useReport';
import SearchStats from './SearchStats';
import {useTheme} from "../theme";

const StatsContainer = styled(Box)`
  padding-top: ${(props) => props.theme.space[5]}px;
`;

const Stats = () => {
  const { membershipStats } = useReport();
  const theme = useTheme();
  const {
    data, loading, reloadingCache, softRefreshInterval, searchStats, reloadStatsSoft, reloadStatsHard,
  } = membershipStats;
  return (
    <StatsContainer>
      <Flex>
        <SearchStats
          onChange={searchStats}
          mb={3}
          mr={3}
          width={{
            xs: 1,
            md: '200px',
          }}
          allStats={data}
        />

        <Tooltip
          color={theme.colors.primary}
          title={`Refresh stats from cache. This is being done automatically every ${softRefreshInterval / 1000} seconds`}>
          <Button disabled={loading} onClick={reloadStatsSoft} icon={<ReloadOutlined />} />
        </Tooltip>

        <Tooltip
          color={reloadingCache ? theme.colors.warning : theme.colors.primary}
          title={reloadingCache ? 'The stats are being reloaded in the background. It takes couple of minutes to complete the process. The wheel will automatically stop spinning once it is done' : 'Forcefully trigger stats reload'}>
          <Button disabled={reloadingCache || loading} onClick={reloadStatsHard} icon={<SyncOutlined spin={reloadingCache} />} />

        </Tooltip>
      </Flex>

      <Flex flexWrap="wrap">
        {data.map(({ id, ...stat }) => (
          <StatCard
            key={id}
            width={{
              xs: '100%',
              md: '200px',
            }}
            mb={3}
            loading={loading}
            mr={{
              xs: 0,
              md: 3,
            }}
            {...stat}
            precision={stat.precision || 0}
          />
        ))}
      </Flex>
    </StatsContainer>
  );
};

export default Stats;
