import { identity } from 'lodash';

export const withCheck = (filterParsers) => Object.entries(filterParsers)
  .reduce((acc, [name, comparator]) => ({
    ...acc,
    [name]: (value) => {
      if (Array.isArray(value)) {
        return value.length ? comparator(value) : identity;
      }

      return value ? comparator(value) : identity;
    },
  }), {});


const createFilterComparator = (filterParsers) => {
  const filterEntries = Object.entries(filterParsers);

  return (filters) => {
    // eslint-disable-next-line
    const activeFilters = filterEntries.filter(([name]) => filters[name] != undefined);
    if (!activeFilters.length) {
      return undefined;
    }

    return (record) => activeFilters.reduce((acc, filterEntry) => {
      const [name, comparator] = filterEntry;
      const matched = comparator(filters[name])(record[name], record);
      return acc && matched;
    }, true);
  };
};


export const createFilter = (filterParsers) => (records, filters) => {
  const finalComparator = createFilterComparator(filterParsers)(filters);
  return finalComparator ? records.filter(finalComparator) : records;
};
