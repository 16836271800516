import React from 'react';
import { Alert, Button, Tooltip } from 'antd';
import { Flex, Box } from 'rebass';
import { createGlobalStyle } from 'styled-components';
import { RetweetOutlined, RollbackOutlined, UsergroupDeleteOutlined } from '@ant-design/icons';
import MembersTable from './MembersTable';
import MemberFilters from './MemberFilters';
import useMembers from './hooks/useMembers';
import ChangeEmailModal from './ChangeEmailModal';
import InviteMemberModal from './InviteMemberModal';
import { ACTIONS } from './constants';
import ChangePhoneModal from "./ChangePhoneModal";
import CsvExport from "../../common/molecules/CsvExport";
import memberColumns from "./memberColumns";
import {formatDate} from "../../common/dates";
import {sentenceCase} from "text-case";

const MembersStyles = createGlobalStyle`
  .member-selected {
    background: ${(props) => props.theme.colors.palette.blue[0]};
  }
 `;

const Members = () => {
  const { state, actions } = useMembers();


  const {
    members,
    unconverting,
    membersLoading,
    unconvertAvailable,
    pagination,
    activeMember,
    activeAction,
    modalOpen,
    executingAction,
    hambsPasswordOutOfSyncAlert,
    isResetHambsPasswordAllowed,
  } = state;
  const {
    loadMembers,
    hideActionModal,
    changeEmail,
    changePhone,
    inviteMember,
    openActionModal,
  } = actions;

  return (
    <>
      <MembersStyles />
      <ChangeEmailModal
        loading={executingAction}
        onCancel={hideActionModal}
        onOk={changeEmail}
        member={activeMember}
        open={modalOpen && activeAction === ACTIONS.CHANGE_EMAIL}
      />
      <InviteMemberModal
        loading={executingAction}
        onCancel={hideActionModal}
        onOk={inviteMember}
        member={activeMember}
        open={modalOpen && activeAction === ACTIONS.INVITE_MEMBER}
      />
      <Flex
        mt={3}
        mb={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <MemberFilters
          loading={membersLoading}
          onSearch={loadMembers}

        />

        <Flex>
          {isResetHambsPasswordAllowed && (
          <Tooltip title="Reset HAMBS password">
            <Button
              disabled={!hambsPasswordOutOfSyncAlert}
              onClick={() => openActionModal(ACTIONS.RESET_HAMBS_PASSWORD)}
              style={{
                marginRight: '8px',
              }}
              icon={<RollbackOutlined />}
            />
          </Tooltip>
          )}

          <Tooltip title="Sync HAMBS password">
            <Button
              disabled={!hambsPasswordOutOfSyncAlert}
              onClick={() => openActionModal(ACTIONS.SYNC_HAMBS_PASSWORD)}
              style={{
                marginRight: '8px',
              }}
              icon={<RetweetOutlined />}
            />
          </Tooltip>

          <Button
            disabled={!unconvertAvailable}
            onClick={() => openActionModal(ACTIONS.UNCONVERT_MEMBERSHIP)}
            loading={unconverting}
            type="danger"
            icon={<UsergroupDeleteOutlined />}
          >
                Unconvert
          </Button>
          <CsvExport
            options={{
              fields: [{
                label: 'Membership ID',
                value: 'membershipId'
              }, {
                label: 'Person ID',
                value: 'personId'
              }, {
                label: 'Email',
                value: 'email'
              }, {
                label: 'Date of birth',
                value: 'dob'
              }, {
                label: 'Cover status',
                value: 'coverStatus'
              }, {
                label: 'Online access',
                value: 'onlineAccess'
              }],
              transformers: [(data) => ({
                  membershipId: data.membershipId,
                  personId: data.personId,
                  email: data.email,
                  dob: formatDate(data.dob),
                  coverStatus: sentenceCase(data.coverStatus),
                  onlineAccess: data.onlineAccess ? 'Yes' : 'No'
                })]
            }}
            name="members"  dataSource={members}  />
        </Flex>


      </Flex>
      {hambsPasswordOutOfSyncAlert && (
        <Box mb={3}>
          <Alert
            {...hambsPasswordOutOfSyncAlert}
          />
        </Box>

      )}

      <MembersTable
        onAction={openActionModal}
        onChange={loadMembers}
        loading={membersLoading}
        members={members}
        pagination={pagination}
      />
    </>
  );
};

export default Members;
