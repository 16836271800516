import React from 'react';
import { Tabs } from 'antd';
import MembersManage from './members-manage';
import ConversionLogs from './conversion-logs';
import { USER_GROUPS } from '../common/constants';
import Can from '../auth/Can';

const Members = () => (
  <Can
    groups={[USER_GROUPS.ADMIN.id, USER_GROUPS.SYSTEM.id]}
    yes={() => (
      <Tabs items={[{
        key: 'members-manage',
        label: 'Members manage',
        children: <MembersManage />
      }, {
        key: 'conversion-logs',
        label: 'Conversion logs',
        children: <ConversionLogs />,
      }]} />
    )}
    no={() => (<MembersManage />)}
  />

);

export default Members;
