import { string } from 'yup';


// according to AWS docs
const allowedSpecialCharacters = [
  '^',
  '$',
  '*',
  '.',
  '[',
  ']',
  '{',
  '}',
  '(',
  ')',
  '?',
  '-',
  '"',
  '!',
  '@',
  '#',
  '%',
  '&',
  '/',
  '\\',
  ',',
  '>',
  '<',
  "'",
  ':',
  ';',
  '|',
  '_',
  '~',
  '`',
];

export const specialCharsRegex = new RegExp(
  `[\\${allowedSpecialCharacters.join('\\')}]+`,
);

export const MIN_PASSWORD_LENGTH = 8;

export const password = (rule, value, callback) => {
  const validator = string()
    .min(MIN_PASSWORD_LENGTH, 'Password must contain at least 8 characters')
    .matches(/\d+/, 'Password must contain at least one digit')
    .matches(/[a-z]+/, 'Password must contain at least one lowercase letter ')
    .matches(/[A-Z]+/, 'Password must contain at least one uppercase letter')
    .matches(
      specialCharsRegex,
      'Password must contain at least 1 special character',
    );

  try {
    validator.validateSync(value);
    callback();
  } catch (error) {
    callback(error);
  }
};

export const email = (rule, value, callback) => {
  const validator = string().email();
  try {
    validator.validateSync(value);
    callback();
  } catch (error) {
    callback(error);
  }
};


function trim(x) {
  return x && x.trim ? x.trim() : '';
}


function formatPhoneE164(value) {
  return trim(value)
    .replace(/[()\s]/g, '')
    .replace(/^0/, '+61');
}


const MOBILE_REGEX = /^\+614[0-9]{8}$/;


export const mobileNumber = (rule, value, callback) => {
  const validator = string()
    .transform(formatPhoneE164)
    .matches(MOBILE_REGEX, value);
  try {
    validator.validateSync(value);
    callback();
  } catch (error) {
    callback('Invalid mobile number');
  }
};

const PHONE_REGEX = /^\+61[1-9][0-9]{8}$/;

export const phoneNumber = (message = 'Invalid phone number.') =>
  mixed().test({
    name: 'phone',
    test: value => {
      if (!value) {
        return true;
      }

      const transformed = formatPhoneE164(value);

      return (
        MOBILE_REGEX.test(transformed) || PHONE_REGEX.test(transformed)
      );
    },
    message,
  });

export const validateConfirmPassword = (form, passwordFiledName = 'password') => (rule, value, callback) => {
  if (form.getFieldValue(passwordFiledName) !== value) {
    callback('Confirm password does not match password');
  } else {
    callback();
  }
};
