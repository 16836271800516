import React from 'react';
import { Popover } from 'antd';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { darken } from 'polished';
import useAuth from './useAuth';
import routePaths from '../routing/routePaths';


const UserBoxContainer = styled(Flex)`
cursor: pointer;
`;


const StyledMenuItem = styled(Box)`
  &&& {
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
  &:hover {
  color: ${(props) => darken(0.5, props.theme.colors.primary)}
  }
  }
`;

const getName = userData =>
  userData?.signInUserSession?.idToken?.payload?.given_name
    ? `${userData.signInUserSession.idToken.payload.given_name} ${userData.signInUserSession.idToken.payload.family_name || ''}`
    : userData?.signInUserSession?.idToken?.payload?.email || userData?.username?.replace?.(/^[^_]+_([^_]+)/, '$1') || 'N/A';

const getInitials = userData =>
  userData?.signInUserSession?.idToken?.payload?.given_name
    ? `${userData.signInUserSession.idToken.payload.given_name[0]}${userData.signInUserSession.idToken.payload.family_name?.[0] || ''}`
    : 'N/A';

const UserAvatar = styled(Flex)`
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.palette.blue[9]};
  color: #fff;
`;

const UserBox = () => {
  const { logout, userData } = useAuth();
  const history = useHistory();
  const menu = (
    <Box>
      <StyledMenuItem
        p={2}
        onClick={() => {
          history.push(routePaths.changePassword);
        }}
      >
Change password
      </StyledMenuItem>
      <StyledMenuItem p={2} onClick={logout}>Logout</StyledMenuItem>
    </Box>
  );

  return (
    <Popover content={menu} trigger="click">
      <UserBoxContainer alignItems="center">
        <UserAvatar>{getInitials(userData)}</UserAvatar>

        <Box ml={2}>{getName(userData)}</Box>
      </UserBoxContainer>
    </Popover>
  );
};

export default UserBox;
