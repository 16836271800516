import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';

import paths from './routePaths';
import LoginPage from '../auth/LoginPage';
import DashboardPage from '../dashboard/DashboardPage';
import PrivateRoute from './PrivateRoute';
import ChangePasswordPage from '../auth/ChangePasswordPage';


const TopLevelRoutes = () => (
  <Switch>
    <Route exact path={paths.login}>
      <LoginPage />
    </Route>
    <Route exact path={paths.changePassword}>
      <ChangePasswordPage />
    </Route>
    <PrivateRoute path={paths.dashboard}>
      <DashboardPage />
    </PrivateRoute>
  </Switch>
);

export default TopLevelRoutes;
