import { useState } from 'react';
import { useNotification } from '../../../common/providers/Notification';

const useActionLoader = () => {
  const TAKING_LONG_TIMER = 7000;
  const [loaders, setLoaders] = useState({});
  const notification = useNotification();
  const timers = {};
  return {
    getLoader(member) {
      return member ? loaders[member.id] : false;
    },
    setLoader(member, loading) {
      if (!member) {
        console.warn('passing undeifined member in setLoader');
        return;
      }

      const { id } = member;

      clearTimeout(timers[id]);
      setLoaders({
        [id]: loading,
      });

      if (loading) {
        timers[id] = setTimeout(() => {
          const n = notification.info({
            message: 'This actions is taking a bit longer',
            description: 'Feel free to close the modal if you haven\'t done so and do other work. We will take care of things in the background and will let you know when it is completed. If you leave the page you won\'t be notified.',
          });
          console.log(n);
        }, TAKING_LONG_TIMER);
      }
    },
  };
};

export default useActionLoader;
