import React, { useState } from 'react';
import {
  Form, Input, Button, Checkbox, Typography, Alert,
} from 'antd';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';
import { Redirect } from 'react-router';
import { UserOutlined } from '@ant-design/icons';
import routePaths from '../routing/routePaths';
import useAuth from './useAuth';
import { email, password } from '../common/validators';
import { useTheme } from '../theme';
import { PasswordInput } from '../common/atoms/PasswordInput';

const { Title } = Typography;

const StyledContainer = styled(Flex)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;

const StyledForm = styled(Form)`
  width: 400px;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: ${(props) => props.theme.space[3]}px;
`;

const SSO_ENABLED = !!process.env.DHF_AWS_USER_POOL_OAUTH_DOMAIN;

const LoginForm = () => {
  const [hideForm, setHideForm] = useState(SSO_ENABLED);
  const auth = useAuth();
  const {
    login, ssoLogin, loading, isLoggedIn, error,
  } = auth;

  const theme = useTheme();

  if (isLoggedIn) {
    return (
      <Redirect to={{
        pathname: routePaths.dashboard,
      }}
      />
    );
  }

  return (
    <StyledContainer width={1} justifyContent="center" alignItems="center">
      <StyledForm
        initialValues={{
          username: auth.rememberedUsername,
          rememberUsername: !!auth.rememberedUsername,
        }}
        onFinish={login}
      >
        <Title
          style={{
            textAlign: 'center',
            marginBottom: `${theme.space[5]}px`,
          }}
          level={2}
        >
          {' '}
        Admin Login
        </Title>

          {!hideForm && (
            <>
              <Form.Item
              name="username"
              rules={[{ required: true, message: 'Please input your email!' }, {
                validator: email,
              }]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Username"
                />

              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }, {
                  validator: password,
                }]}
              >
                <PasswordInput />
              </Form.Item>


              <Form.Item name="rememberMe" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              </>
          )}
          {error && <StyledAlert message={error} type="error" showIcon />}
          <Form.Item>
            {SSO_ENABLED ? (
              <Box gap="24px">
                {hideForm ? (
                  <Button size="large" block type="primary" htmlType="button" onClick={(e) => {
                    e.preventDefault();
                    setHideForm(!hideForm)
                    }}>Log in</Button>
                ) : (
                  <Button loading={loading} size="large" block type="primary" htmlType="submit">Log in</Button>
                )}
                </Box>
            ) : (
              <Box gap="24px">
                <Button loading={loading} size="large" block type="primary" htmlType="submit">Log in</Button>
              </Box>
            )}
          </Form.Item>
          {hideForm ? (
            <Button hidden={!SSO_ENABLED} size="large" block type="primary" htmlType="button" onClick={ssoLogin}>SSO Log in</Button>
          ) : (
            SSO_ENABLED && (
              <Box gap="24px">
              <Button size="large" block type="link" htmlType="button" onClick={() => setHideForm(!hideForm)}>Back</Button>
            </Box>
            )
          )}
      </StyledForm>
    </StyledContainer>
  );
};


export default LoginForm;
