import { useMutation } from '@apollo/client';
import InviteMemberMutation from '../../../queries/inviteMemberMutation.graphql';

export default () => {
  const [mutation, ...rest] = useMutation(InviteMemberMutation);

  const inviteMember = (membershipId, invitePerson) => mutation({
    variables: {
      input: {
        membershipId,
        invitePerson,
      },
    },
  });

  return [inviteMember, ...rest];
};
