import moment from 'moment';

const toMoment = (dateString) => moment(dateString, moment.ISO_8601);

export const dateTimeString = 'DD/MM/YYYY HH:mm:ss';
export const dateTimeShortString = 'DD/MM/YY HH:mm';
export const dateString = 'DD/MM/YYYY';
export const dateShortString = 'DD/MM/YY';
export const wordyString = 'DD MMMM YYYY';
export const shortWordyString = 'DD MMM YYYY';
export const shortString = 'MM/YYYY';

export const formatDate = (value) => moment(value, moment.ISO_8601).format(dateString);

export const formatShortDate = (value) => moment(value, moment.ISO_8601).format(dateShortString);

export const formatDateTime = (value) => moment(value, moment.ISO_8601).format(dateTimeString);

export const formatShortDateTime = (
  value,
) => moment(value, moment.ISO_8601).format(dateTimeShortString);

export const wordyFormat = (value) => moment(value, moment.ISO_8601).format(wordyString);

export const shortWordyFormat = (value) => moment(value, moment.ISO_8601).format(shortWordyString);

export const shortFormat = (value) => toMoment(value).format(shortString);

export const compare = (value1, value2, limitTo) => {
  const date1 = toMoment(value1);
  const date2 = toMoment(value2);
  if (date1.isBefore(date2)) {
    return -1;
  }

  if (date1.isSame(date2, limitTo)) {
    return 0;
  }

  return 1;
};

export const compareDateOnly = (value1, value2) => compare(value1, value2, 'day');

export const isInPast = (date) => toMoment(date).isBefore(moment().subtract(1, 'day'));

export const smartFormat = (value) => {
  const date = toMoment(value);
  const format = date.year() === moment().year() ? 'Do MMMM' : 'Do MMMM, YYYY';
  return date.format(format);
};

export const toDate = toMoment;

export const date = moment;
