/* eslint-disable no-empty, no-underscore-dangle, no-param-reassign */
import identity from 'lodash/identity';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, ApolloLink} from '@apollo/client';
import { Auth } from 'aws-amplify';
import uuid from 'uuid/v4';

import { InMemoryCache } from '@apollo/client/cache';

import fetch from 'cross-fetch';
import { isUnauthenticatedMembership } from '../auth/AuthUtils';
import {notification} from "antd";

const getUri = () => {
  if (process.env.DHF_API_HOSTNAME) {
    return `https://${process.env.DHF_API_HOSTNAME}/graphql`;
  }

  return `http://localhost:${process.env.PORT || '9000'}/graphql`;
};

// Saves the request id from the response so it can be resent with future requests
const requestIdLink = new ApolloLink((operation, forward) => forward(operation).map((response) => {
  const context = operation.getContext();
  const {
    response: { headers },
  } = context;

  if (headers) {
    const requestId = headers.get('x-request-id');
    if (requestId) {
      sessionStorage.setItem('REQ_ID', requestId);
    }
  }

  return response;
}));

const authLink = setContext(async (request, prevContext) => {
  const authHeaders = { ...prevContext.headers };

  try {
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();

    if (token) {
      authHeaders.authorization = `Bearer ${token}`;
    }

    if (!authHeaders['x-request-id']) {
      let REQ_ID = sessionStorage.getItem('REQ_ID');
      if (!REQ_ID) {
        REQ_ID = uuid();
      }
      authHeaders['x-request-id'] = REQ_ID;
    }
  } catch (err) {}

  return { headers: authHeaders };
});

const wafLink =  setContext(async (request, prevContext) => {
  const wafHeaders = { ...prevContext.headers };

  try {
    if (typeof window !== 'undefined' && window.AwsWafIntegration?.getToken) {
      wafHeaders['x-aws-waf-token'] = await window.AwsWafIntegration.getToken();
    }
  } catch (err) {}

  return { headers: wafHeaders };
});

const httpLink = new BatchHttpLink({
  uri: getUri(),
  batchMax: 10,
  batchInterval: 10,
  fetch,
  includeExtensions: true,
  credentials: 'same-origin',
  fetchOptions: {
    credentials: 'same-origin',
  },
});

const errorHandler = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error = {}) => {
      const { message, path = [], extensions = {} } = error;
      if (message !== 'PersistedQueryNotFound') {
        console.warn(
          `[GraphQL error] ${message} ${path ? `(${path.join(' -> ')})` : ''})`,
        );
      }

      if (path.join('.') === 'oms.admin.membership' || isUnauthenticatedMembership(error)) {
        return;
      }

      if (extensions.code === 'UNAUTHENTICATED') {
        Auth.signOut().then(() => {
          if (!window.location.pathname.includes('/login')) {
            window.location.pathname = '/login';
          }
        });
      } else {
        notification.error({
          placement: 'topLeft',
          message: `GraphQL error: ${path.join(' -> ')}`,
          content: message
        })
      }
    });
  }

  if (networkError) {
    console.warn(`[Network error]: ${networkError}`);
  }
};

const client = new ApolloClient({
  name: 'DHF Admin Website',
  version: '0.0.1',
  cache: new InMemoryCache({
    addTypename: true,
    dataIdFromObject: (object) => {
      object = object || {};
      const id = object.id || object._id || object.reference || uuid();
      const reference = id !== object.reference ? object.reference : '';
      return [object.__typename, id, reference, object.timestamp].filter(identity).join(':');
    },
  }),
  link: ApolloLink.from([
    authLink,
    requestIdLink,
    wafLink,
    onError(errorHandler),
    httpLink,
  ]),
});

export default client;
