import useAuth from '../auth/useAuth';
import {path} from 'lodash/fp';
import {
  CognitoIdentityProviderClient,
  AdminGetUserCommand, AdminDeleteUserCommand,
  ListUsersInGroupCommand, AdminAddUserToGroupCommand,
  AdminCreateUserCommand, ListUsersCommand,
  AdminUpdateUserAttributesCommand, AdminRemoveUserFromGroupCommand
} from '@aws-sdk/client-cognito-identity-provider';

export default () => {
  const auth = useAuth();
  const getCognitoISP = async () => {
    const credentials = await auth.getCurrentCredentials();
    if (credentials instanceof Error) {
      throw credentials;
    }
    const client = new CognitoIdentityProviderClient({
      credentials,
      region: process.env.DHF_AWS_COGNITO_REGION || process.env.AWS_REGION,
    });

    const appendUserPoolId = (params) => ({
      ...params,
      UserPoolId: process.env.DHF_AWS_USER_POOLS_ID
    });

    const api = {
      adminGetUser: params => client.send((new AdminGetUserCommand(appendUserPoolId(params)))),
      adminDeleteUser: params => client.send((new AdminDeleteUserCommand(appendUserPoolId(params)))),
      listUsersInGroup: params => client.send((new ListUsersInGroupCommand(appendUserPoolId(params)))),
      adminAddUserToGroup: params => client.send((new AdminAddUserToGroupCommand(appendUserPoolId(params)))),
      adminCreateUser: params => client.send((new AdminCreateUserCommand(appendUserPoolId(params)))),
      listUsers: params => client.send((new ListUsersCommand(appendUserPoolId(params)))),
      adminUpdateUserAttributes: params => client.send((new AdminUpdateUserAttributesCommand(appendUserPoolId(params)))),
      adminRemoveUserFromGroup: params => client.send((new AdminRemoveUserFromGroupCommand(appendUserPoolId(params)))),
    }

    const getUserByEmail = email => {
      return api.listUsers({
        Filter: `email="${email}"`
      }).then(data => {
        return path('Users[0]', data);
      })
    };

    return {
      ...api,
      getUserByEmail
    };
  };

  return {
    getCognitoISP,
  };
};
