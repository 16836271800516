import React, { useMemo } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Table as AntdTable } from 'antd';
import * as sorters from '../tableSorters';

const TableStyles = createGlobalStyle`
  .member-selected {
    background: ${(props) => props.theme.colors.palette.blue[0]};
  }
 `;

const Table = ({ columns, ...props }) => {
  const mappedColumns = useMemo(() => (columns || []).map((column) => (column.sort ? {
    ...column,
    ...sorters[column.sort](column.dataIndex),
  } : column)), [columns]);
  return (
    <>
      <TableStyles />
      <AntdTable
        columns={mappedColumns}
        {...props}
      />
    </>
  );
};

export default Table;
