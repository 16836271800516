import React from 'react';
import FieldsView from './FieldsView';
import SectionTitle from './SectionTitle';

export default {
  title: 'Family Details',
  customRender: ({ data, allData }) => {
    console.log(data);
    return (
      <>
        <FieldsView title="Partner details" data={data.partnerDetails} allData={allData} />

        {data.dependantDetails && <SectionTitle mt={4}>Dependant details</SectionTitle>}
        {data.dependantDetails && data.dependantDetails.map((dependant) => (
          <FieldsView data={dependant} allData={allData} />
        ))}
      </>
    );
  },
};
