import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import apolloClient from './apollo/client';
import TopLevelRoutes from './routing/TopLevelRoutes';

import 'antd/dist/antd.min.css';
import { theme } from './theme';
import { NotificationProvider } from './common/providers/Notification';
import { AuthProvider } from './auth/AuthContext';
import useScript from './common/hooks/useScript';

function App() {
  process.env.DHF_WAF_CHALLENGE_SCRIPT && useScript(process.env.DHF_WAF_CHALLENGE_SCRIPT);

  return (
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <NotificationProvider>
            <AuthProvider>
              <TopLevelRoutes />
            </AuthProvider>
          </NotificationProvider>
        </ThemeProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
}

export default App;
