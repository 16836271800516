import React from 'react';
import { Table, Typography } from 'antd';
import { Flex, Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { sentenceCase } from 'text-case';
import MemberAction from './MemberAction';
import { ACTIONS } from './constants';
import { formatDate } from '../../common/dates';
import { useTheme } from '../../theme';
import memberColumns from "./memberColumns";

const StyledTable = styled(Table)`
  .ant-table-tbody {
  background: ${(props) => props.theme.colors.white};
  }
`;

const MembersTable = ({
  loading, members, pagination, onChange, onAction,
}) => {
  const theme = useTheme();
  const columns = memberColumns({ onAction, theme });

  return (
      <StyledTable
        rowClassName={(record) => (record.highlighted ? 'member-selected' : '')}
        rowKey={(member) => member.id}
        loading={loading}
        onChange={onChange}
        pagination={pagination}
        columns={columns}
        dataSource={members}
      />
  );
};

export default MembersTable;
