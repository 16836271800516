import React from 'react';
import { Button as AntButton, Tooltip } from 'antd';
import { layout, space } from 'styled-system';
import styled from 'styled-components';
import { DownloadOutlined } from '@ant-design/icons';

const StyledButton = styled(AntButton)(
  layout,
  space,
);

const Button = React.forwardRef(({ tooltip, children, ...props }, ref) => {
  const button = <StyledButton ref={ref} {...props}>{children}</StyledButton>;
  if (tooltip) {
    const { title, ...restTooltipProps } = typeof tooltip === 'string' ? {
      title: tooltip,
    } : tooltip;

    return (
      <Tooltip title={title} {...restTooltipProps}>
        {button}
      </Tooltip>
    );
  }

  return button;
});

export default Button;
