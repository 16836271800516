import React from 'react';
import {
  Alert, Form, Input, Select,
} from 'antd';
import FormModal from '../common/molecules/FormModal';
import { email } from '../common/validators';
import { FORM_MODES, USERS_GROUP_LIST } from '../common/constants';

const ManageUserModal = ({
  form, error, user = {}, loading, mode = 'add', ...props
}) => (
  <FormModal initialValues={user} loading={loading} form={form} title={`${mode === 'add' ? 'Add' : 'Edit'} user`} {...props}>
    <Form.Item
      label="First Name"
      name="given_name"
      rules={[{
        required: true,
        message: 'First Name is required',
      }]}
    >
      <Input type="text" placeholder="First Name" />
    </Form.Item>
    <Form.Item
      name="family_name"
      label="Last Name"
      rules={[{
        required: true,
        message: 'Last Name is required',
      }]}
    >
      <Input type="text" placeholder="Last Name" />
    </Form.Item>
    <Form.Item
      name="email"
      label="Email"
      rules={[{
        required: true,
        message: 'email is required',
      }, {
        validator: email,
      }]}
    >

      <Input disabled={FORM_MODES.EDIT === mode} type="text" placeholder="email" />
    </Form.Item>
    <Form.Item
      name="group"
      label="User Group"
      rules={[{
        required: true,
        message: 'User Group is required',
      }]}
    >
      <Select disabled={user.isCurrentUser}>
        {USERS_GROUP_LIST.map((group) => (
          <Select.Option value={group.id} key={group.id}>{group.label}</Select.Option>
        ))}
      </Select>
    </Form.Item>
    {error && (
      <Alert type="error" message={error} />
    )}
  </FormModal>
);

export default ManageUserModal;
