import { useMutation } from '@apollo/client';
import AvantUnlinkMemberMutation from '../../../queries/avantUnlinkMemberMutation.graphql';
import { updatePersonStore } from '../storeUpdaters';

export default () => {
  const [mutation, ...rest] = useMutation(AvantUnlinkMemberMutation);

  const unlinkMutation = (member) => mutation({
    variables: {
      input: {
        id: member.id,
      },
    },
    update(store, { data: { adminAvantUnlinkPerson } }) {
      if (adminAvantUnlinkPerson.success) {
        updatePersonStore(store, {
          id: member.id,
          updateDetails: {
            avantLinked: false,
          },
          membershipId: member.membershipId,
        });
      }
    },
  });

  return {
    unlink: [unlinkMutation, ...rest],
  };
};
