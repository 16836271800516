import React from 'react';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import Table from './Table';
import Button from '../atoms/Button';

const StyledLogsTable = styled(Box)`
  position: relative;
`;

const StyledLotNavigation = styled(Flex)`
  &&& {
     ${(props) => (!props.emptyResult ? `
      position: absolute;
   right: 0;
   bottom: 16px;
   ` : `
    margin-top: 16px;
    padding-bottom: 16px;
   `)}
  }

`;

const LogsTable = ({
  onLotChange, lots = [], currentLotIndex, dataSource = [], loading, ...props
}) => (
  <StyledLogsTable>
    <Table
      pagination={{
        position: ['bottomLeft'],
      }}
      loading={loading}
      dataSource={dataSource}
      {...props}
    />

    {!loading && lots.length > 1 && (
    <StyledLotNavigation emptyResult={!dataSource.length} justifyContent="flex-end">
      <Button
        mr={2}
        disabled={loading || currentLotIndex <= 0}
        onClick={() => {
          onLotChange(currentLotIndex - 1);
        }}
      >
            Prev Lot
      </Button>

      <Button
        disabled={loading || currentLotIndex === lots.length - 1}
        onClick={() => {
          onLotChange(currentLotIndex + 1);
        }}
      >
            Next Lot
      </Button>
    </StyledLotNavigation>
    )}
  </StyledLogsTable>
);

export default LogsTable;
