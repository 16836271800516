import React from 'react';
import {
  Form, Input, Select, Tag,
} from 'antd';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { space, layout } from 'styled-system';
import DateRangeFilter from '../../common/molecules/DateRangeFilter';
import Button from '../../common/atoms/Button';

const StyledForm = styled(Form)`
   width: 100%;
`;

const FormItem = styled(Form.Item)(
  space,
  layout,
);

const statusColors = {
  'request-timeout': 'blue',
  success: 'green',
  error: 'red',
  incomplete: 'yellow',
  unknown: 'orange',
};

const MafLogsFilters = ({
  form, loading, onSearch,
}) => (
  <StyledForm
    form={form}
    name="maf-logs-filters-form"
    onFinish={(values) => {
      onSearch(values);
    }}
  >
    <Flex>
      <FormItem width={1 / 4} mr={2} name="membershipId">
        <Input
          placeholder="Membership"
          type="number"
          disabled={loading}
        />
      </FormItem>
      <FormItem
        mr={2}
        width={1 / 4}
        name="reference"
      >
        <Input
          placeholder="Reference"
          disabled={loading}
        />
      </FormItem>
      <FormItem mr={2} width={3 / 10} name="status">
        <Select
          disabled={loading}
          placeholder="status"
          mode="multiple"
          tagRender={({ value, label, ...props }) => <Tag color={statusColors[value]}>{value}</Tag>}
        >
          <Select.Option value="request-timeout">
            <Tag color={statusColors['request-timeout']}>request-timeout</Tag>
          </Select.Option>
          <Select.Option value="success">
            <Tag color={statusColors.success}>success</Tag>
          </Select.Option>

          <Select.Option value="error">
            <Tag color={statusColors.error}>error</Tag>
          </Select.Option>
          <Select.Option value="unknown">
            <Tag color={statusColors.unknown}>unknown</Tag>
          </Select.Option>
          <Select.Option value="incomplete">
            <Tag color={statusColors.incomplete}>incomplete</Tag>
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem mr={2} width={3 / 10} name="date">
        <DateRangeFilter
          disabled={loading}
        />
      </FormItem>

      <Button
        width={1 / 10}
        htmlType="submit"
        type="primary"
        disabled={loading}
      >
        Search
      </Button>
    </Flex>
    <Flex />
  </StyledForm>
);

export default MafLogsFilters;
