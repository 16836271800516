import {Tag, Tooltip} from 'antd';
import React from 'react';

const MemberAction = ({
  actions = {}, onClick, color, disabled, children, member, id, ...props
}) => {
  return <div>
    {(actions || {})[id] && (
    <Tooltip title={disabled ? disabled : ''}>
      <Tag
        disabled
        style={{
          cursor: disabled ? 'default' : 'pointer',
        }}
        color={disabled? '#ccc' : color}
        onClick={(evt) => {
          if (!disabled) {
            onClick(id, member);
          } else {
            evt.nativeEvent.stopPropagation();
            evt.nativeEvent.preventDefault();
          }
        }}
        key={id}
        {...props}
      >
        {children}
      </Tag>
    </Tooltip>
    )}
  </div>;
};

export default MemberAction;
