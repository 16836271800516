import moment from 'moment';

const getStringSorter = (columnKey) => (a, b) => {
  // eslint-disable-next-line no-param-reassign
  a = (a[columnKey] || '').toLowerCase();

  // eslint-disable-next-line no-param-reassign
  b = (b[columnKey] || '').toLowerCase();
  if (a < b) {
    return -1;
  }

  if (a === b) {
    return 0;
  }

  return 1;
};

const sortDirections = ['ascend', 'descend'];

export const string = (columnKey) => ({
  sorter: getStringSorter(columnKey),
  sortDirections,
});

export const date = (columnKey) => ({
  sorter: (a, b) => moment(a[columnKey] || 0).valueOf() - moment(b[columnKey] || 0).valueOf(),
  sortDirections,
});

export const number = (columnKey) => ({
  sorter: (a, b) => a[columnKey] - b[columnKey],
  sortDirections,
});
