export const ACTIONS = {
  RESET_PASSWORD: 'resetPassword',
  INVITE_MEMBER: 'inviteMember',
  CHANGE_EMAIL: 'changeEmail',
  UNINVITE_MEMBER: 'uninviteMember',
  UNCONVERT_MEMBERSHIP: 'unconvertMembership',
  UNLINK_AVANT: 'unlinkAvant',
  RESET_HAMBS_PASSWORD: 'resetHambsPassword',
  SYNC_HAMBS_PASSWORD: 'syncHambsPassword',
  RESEND_EMAIL_CONFIRMATION: 'resendEmailConfirmation',
  TOGGLE_MFA: 'toggleMFA'
};

export const MEMBER_ROLE = {
  DEPENDANT: 'DEPENDANT',
  PARTNER: 'PARTNER',
  MEMBER: 'MEMBER',
};

export const UNKNOWN_ID = 'UNKNOWN';

export const POLICY_COVER_STATUS = {
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
  TERMINATED: 'TERMINATED',
  UNKNOWN: UNKNOWN_ID,
};

export const MFA_METHODS = {
  SMS_MFA: 'SMS_MFA',
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA'
}
