import { useQuery } from '@apollo/client';
import { path } from 'lodash/fp';
import MembershipStatsQuery from '../queries/membershipStatsQuery.graphql';

const extractData = path('oms.admin.report.membershipStats');
const useMembershipStatsQuery = (options) => {
  const {
    data, loading, error, ...rest
  } = useQuery(MembershipStatsQuery, {
    ...options,
    onCompleted: options.onCompleted && ((value) => {
      return options.onCompleted(extractData(value))
  })
  });


  // for now we only have these
  // expecting more of them coming in the near future
  return {
    data: extractData(data),
    loading,
    error,
    ...rest,
  };
};

export default useMembershipStatsQuery;
