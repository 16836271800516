import useAuth from './useAuth';

const Can = ({
  groups = [], yes, no = () => null, ...props
}) => {
  const { canAccess } = useAuth();
  return canAccess(groups) ? yes(props) : no(props);
};

export default Can;
