import React from 'react';
import {
  Modal, Button, Form,
} from 'antd';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import { useTheme } from '../../theme';

const StyledModal = styled(Modal)`
  .ant-modal-body {
  padding: 0;
  }
  
  .modal-form {
  padding: ${(props) => props.theme.space[4]}px;
  }
`;

const Footer = styled(Flex)`
  //border-top: 1px solid ${(props) => props.theme.colors.borders}
`;

const FormModal = ({
  children, onOk, initialValues, loading, onCancel, okText = 'Submit', cancelText = 'Cancel', form, ...props
}) => {
  const theme = useTheme();
  return (
    <StyledModal onCancel={onCancel} destroyOnClose footer={null} {...props}>
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        initialValues={initialValues}
        onFinish={onOk}
      >
        <Box p={3}>
          {children}
        </Box>
        <Footer py={3} px={3} alignItems="center" justifyContent="flex-end">
          <Button
            loading={loading}
            htmlType="submit"
            style={{
              width: 90,
              marginRight: `${theme.space[2]}px`,
            }}
            type="primary"
          >
            {okText}
          </Button>
          <Button
            style={{
              width: 90,
            }}
            onClick={onCancel}
          >
            {cancelText}
          </Button>
        </Footer>
      </Form>

    </StyledModal>
  );
};

export default FormModal;
