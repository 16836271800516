import React from 'react';
import ConversionLogsFilters from './ConversionLogsFilters';
import ConversionLogsTable from './ConversionLogsTable';
import useConversionLogs from './useConversionLogs';

const ConversionLogs = () => {
  const { state, actions } = useConversionLogs();

  return (
    <>
      <ConversionLogsFilters form={state.filtersForm} onSearch={actions.search} my={3} />
      <ConversionLogsTable
        lots={state.lots}
        currentLotIndex={state.currentLotIndex}
        onLotChange={actions.changeLot}
        logs={state.logs}
        loading={state.loading}
      />
    </>
  );
};

export default ConversionLogs;
