import { useApolloClient, useMutation } from '@apollo/client';
import UpdateMemberMutation from '../../../queries/updateMemberMutation.graphql';
import { updatePersonStore } from '../storeUpdaters';

export default () => {
  const [mutation, ...rest] = useMutation(UpdateMemberMutation);
  const client = useApolloClient();

  const updateMember = (membershipId, updateDetails) => mutation({
    variables: {
      input: {
        membershipId,
        updatePerson: updateDetails,
      },
    },
    update(store, { data: { adminUpdatePerson } }) {
      if (adminUpdatePerson.success && updateDetails?.id) {
        updatePersonStore(store, {
          id: updateDetails.id,
          updateDetails: {
            ...updateDetails,
            emailVerified: adminUpdatePerson?.person?.emailVerified,
          },
          membershipId,
        });
      }
    },
  });

  return [updateMember, ...rest];
};
