import React from 'react';
import { Tag } from 'antd';

const StatusCode = ({ value }) => {
  let color;

  if (value >= 200 && value < 300) {
    color = 'green';
  } else if (value >= 400 && value < 500) {
    color = 'orange';
  } else if (value >= 500 && value < 600) {
    color = 'red';
  }

  return <Tag color={color}>{value}</Tag>;
};

export default StatusCode;
