import { pipe } from 'lodash/fp';
import { createFilter, withCheck } from '../../common/filters';


const filterParsers = {
  status: (value = []) => (recordValue) => {
    const mappedRecordValue = recordValue === 'OK' ? recordValue : 'ERROR';
    return value.includes(mappedRecordValue);
  },
};

export default pipe(
  withCheck,
  createFilter,
)(filterParsers);
