import React from 'react';
import { Flex } from 'rebass';
import { UsergroupAddOutlined } from '@ant-design/icons';
import UsersTable from './UsersTable';
import UserFilters from './UsersFilters';
import Button from '../common/atoms/Button';
import useUsers from './useUsers';
import ManageUserModal from './ManageUserModal';


const Users = () => {
  const { state, actions } = useUsers();
  const {
    users, usersLoading,
    formMode, selectedUser,
    isManageUserModalVisible, modalLoading, manageUserError, tableState,
  } = state;
  const {
    openAddUserModal,
    hideManageUserModal,
    openDeleteUserModal,
    openEditUserModal,
    openResetPasswordModal,
    handleManageModalOk,
    handleTableChange,
    searchUsers,
  } = actions;

  return (
    <>
      <ManageUserModal
        onCancel={hideManageUserModal}
        open={isManageUserModalVisible}
        mode={formMode}
        user={selectedUser}
        onOk={handleManageModalOk}
        loading={modalLoading}
        error={manageUserError}
      />
      <Flex
        mt={3}
        mb={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <UserFilters
          loading={usersLoading}
          onSearch={searchUsers}
          width={300}
        />


        <Flex>
          <Button
            onClick={openAddUserModal}
            tooltip={{
              title: 'Add new user',
              placement: 'left',
            }}
            icon={<UsergroupAddOutlined />}
          />
        </Flex>


      </Flex>
      <UsersTable
        {...tableState}
        onChange={handleTableChange}
        onEdit={openEditUserModal}
        onDelete={openDeleteUserModal}
        onPasswordReset={openResetPasswordModal}
        loading={usersLoading}
        users={users}
      />
    </>
  );
};

export default Users;
