import React from 'react';
import { formatDate } from '../../../common/dates';
import FieldsView from './FieldsView';
import { dropProps } from '../../../common/util';

export default {
  title: 'Advanced quote',
  fields: {
    dob: {
      label: 'Date of birth',
      value: formatDate,
    },
  },
  customRender: ({ data, allData }) => {
    const member = {
      lhcExempt: data.lhcExempt,
      dob: data.dob,
    };

    const common = dropProps(['lhcExempt', 'dob', 'partner'], data);
    const { partner } = data;
    return (
      <>
        <FieldsView
          data={common}
          allData={allData}
          divider
        />
        <FieldsView
          title="Member"
          data={member}
          allData={allData}
          divider={!!partner}
          fields={{
            dob: {
              label: 'Date of birth',
              value: formatDate,
            },
          }}
        />

        {data.partner && (
        <FieldsView
          title="Partner"
          data={partner}
          allData={allData}
          fields={{
            dob: {
              label: 'Date of birth',
              value: formatDate,
            },
          }}
        />
        )}
      </>
    );
  },
};
