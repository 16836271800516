import { path } from 'lodash/fp';

import MedicareRebates from '../../queries/medicareRebates.graphql';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import usePagedQuery from '../../common/hooks/usePagedQuery';

export default () => {
  const [getLogs, { data, ...rest }] = usePagedQuery(MedicareRebates, {
    nextLot: path('oms.admin.logs.medicareRebate.next'),
    queryInput: (filters) => ({
      startTime: filters.date.start,
      endTime: filters.date.end,
      id: filters.id,
      reference: filters.reference,
      cardNumber: filters.cardNumber,
    }),
  });

  return [getLogs, {
    data: path('oms.admin.logs.medicareRebate.logs', data),
    ...rest,
  }];
};
