import React, { useState } from 'react';

import {
  Layout, Menu,
} from 'antd';
import styled from 'styled-components';
import {
  Route, Switch, NavLink, useLocation,
} from 'react-router-dom';
import { Flex } from 'rebass';
import {
  DotChartOutlined, FileSearchOutlined, TeamOutlined, UserOutlined,
} from '@ant-design/icons';
import Members from '../members';
import routePaths from '../routing/routePaths';
import UserBox from '../auth/UserBox';
import Stats from '../stats/Stats';
import Users from '../users/Users';
import MenuItem from '../common/molecules/MenuItem';
import Logs from '../logs';
import {useHistory} from "react-router";
import useAuth from "../auth/useAuth";
import {USER_GROUPS} from "../common/constants";
import Can from "../auth/Can";


const {
  Header, Content, Sider,
} = Layout;

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
  position: relative;
`;


const DashboardPage = () => {
  const [collapsed, setCollapsed] = useState(true);
  const history = useHistory();
  const { pathname } = useLocation();
  const { canAccess } = useAuth();
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
        <div className="logo" />
        <Menu onClick={evt => {
          history.push(evt.key);
        }} theme="dark" selectedKeys={[pathname]} mode="inline" items={[{
            key: routePaths.users,
            label: 'Users',
            icon: <TeamOutlined />,
            hide: !canAccess([USER_GROUPS.ADMIN.id])
        }, {
          key: routePaths.members,
          label: 'Members',
          icon: <UserOutlined />
        }, {
          key: routePaths.stats,
          label: 'Stats',
          icon: <DotChartOutlined />
        }, {
          key: routePaths.logs,
          label: 'Logs',
          icon: <FileSearchOutlined />,
          hide: !canAccess([USER_GROUPS.ADMIN.id, USER_GROUPS.SYSTEM.id])
        }].filter(item => !item.hide)}>
        </Menu>
      </Sider>
      <Layout>
        <StyledHeader style={{ background: '#fff', padding: 0 }}>
          <Flex pr={4} width={1} justifyContent="flex-end">
            <UserBox />
          </Flex>
        </StyledHeader>
        <Content style={{ margin: '0 16px' }}>
          <Switch>
            <Route exact path={routePaths.dashboard}>
              <Members />
            </Route>
            <Route path={routePaths.members}>
              <Members />
            </Route>
            <Route path={routePaths.stats}>
              <Stats />
            </Route>
            <Route path={routePaths.users}>
              <Users />
            </Route>
            <Can groups={[USER_GROUPS.ADMIN.id, USER_GROUPS.SYSTEM.id]} yes={() =>
              <Route path={routePaths.logs}>
                <Logs />
              </Route>
            } no={() => null} />

          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardPage;
