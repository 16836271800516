import React from 'react';
import { Form, Input } from 'antd';
import FormModal from '../../common/molecules/FormModal';
import { email, mobileNumber } from '../../common/validators';
import { getMemberFullName } from '../../common/util';

const InviteMemberModal = ({
  form, member = {}, loading, ...props
}) => (
  <FormModal
    initialValues={{
      email: member.email,
      mobile: member.mobilePhone,
    }}
    loading={loading}
    form={form}
    title={`Invite ${getMemberFullName(member)}`}
    {...props}
  >
    <Form.Item
      name="email"
      label="Email"
      rules={[{
        required: true,
        message: 'email is required',
      }, {
        validator: email,
      }]}
    >
      <Input type="text" placeholder="email" />
    </Form.Item>

    <Form.Item
      name="mobile"
      label="Mobile number"
      rules={[{
        required: true,
        message: 'Mobile number is required',
      }, {
        validator: mobileNumber,
      }]}
    >
      <Input type="text" placeholder="mobile number" />
    </Form.Item>
  </FormModal>
);

export default InviteMemberModal;
