import React from 'react';
import { Button, Input } from 'antd';
import { Flex, Box } from 'rebass';
import { path } from 'lodash/fp';
import DateRangeFilter from '../../common/molecules/DateRangeFilter';
import HambsLogsTable, { hambsLogsColumns } from './HambsLogsTable';
import useHambsLogs from './useHambsLogs';
import HambsLogsFilters from './HambsLogsFilters';
import CsvExport from '../../common/molecules/CsvExport';

const HambsLogs = () => {
  const hambsLogs = useHambsLogs();
  return (
    <>
      <Flex flexDirection="column" my={3}>
        <Box mb={2} alignSelf="flex-end">
          <CsvExport
            mb={2}
            dataSource={hambsLogs.state.list}
            name="HAMBS Logs"
            columns={hambsLogsColumns}
          />
        </Box>

        <HambsLogsFilters
          loading={hambsLogs.state.loading}
          form={hambsLogs.state.filtersForm}
          onSearch={hambsLogs.actions.search}
        />

      </Flex>
      <HambsLogsTable
        lots={hambsLogs.state.lots}
        currentLotIndex={hambsLogs.state.currentLotIndex}
        onLotChange={hambsLogs.actions.changeLot}
        loading={hambsLogs.state.loading}
        logs={hambsLogs.state.list}
      />
    </>
  );
};

export default HambsLogs;
