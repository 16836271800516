import useMedicareRebatesQuery from './useMedicareRebatesQuery';
import { filter } from './filters';
// eslint-disable-next-line import/named
import { createClientSideFiltering } from '../../common/hooks/useClientSideFiltering';

const useClientSideFiltering = createClientSideFiltering('medicare-rebates-filters');


export default () => {
  const [getLogs, {
    data, lots, currentLotIndex, loading, error,
  }] = useMedicareRebatesQuery();
  const clientSideFiltering = useClientSideFiltering({
    data,
    getData: getLogs,
    filter,
  });


  return {
    state: {
      ...clientSideFiltering.state,
      loading,
      error,
      lots,
      currentLotIndex,
    },
    actions: {
      ...clientSideFiltering.actions,
      changeLot: (index) => {
        clientSideFiltering.actions.search(clientSideFiltering.state.filters, index);
      },
    },
  };
};
