import React from 'react';
import { DatePicker } from 'antd';
import { path } from 'lodash/fp';
import styled from 'styled-components';
import { dateString, dateTimeString } from '../dates';

const { RangePicker } = DatePicker;

const getStartDate = (value = []) => value && value[0];

const getEndDate = (value = []) => value && value[1];

const StyledRangePicker = styled(RangePicker)`
    width: 100%;
`;

export default ({
  onChange, value, showTime = true, ...props
}) => {
  const rangePickerValue = [path('start')(value), path('end')(value)];
  return (
    <StyledRangePicker
      showTime={showTime}
      format={dateTimeString}
      onChange={(changeValue) => {
        onChange({
          start: getStartDate(changeValue),
          end: getEndDate(changeValue),
        });
      }}
      value={rangePickerValue}
      {...props}
    />
  );
};
