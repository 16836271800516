import { useMutation } from '@apollo/client';
import SetMFAMutation from '../../../queries/setMFAMutation.graphql';
import {updatePersonStore} from "../storeUpdaters";
import {MFA_METHODS} from "../constants";

export default () => {
  const [mutation, ...rest] = useMutation(SetMFAMutation);

  const setMFA = (member, preference) => mutation({
    variables: {
      input: {
        email: member.email,
        preference
      },
    },
    update(store, { data: { adminSetMFA } }) {
      if (adminSetMFA.success) {
        updatePersonStore(store, {
          id: member.id,
          updateDetails: {
            MFASettings: preference.sms?.enabled ? [MFA_METHODS.SMS_MFA] : [],
          },
          membershipId: member.membershipId,
        });
      }
    }
  });

  return [setMFA, ...rest];
};
