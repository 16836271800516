import { useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import {  path } from 'lodash/fp';

import SlowHambsQueries  from '../../queries/slowHambsQueries.graphql';

export default () => {
  const [queryInput, setQueryInput] = useState({});
  const [getQueries, { data, ...rest }] = useLazyQuery(SlowHambsQueries, {
    fetchPolicy: 'network-only',
  });


  console.log(data);
  return [(filters = {}) => {
    getQueries({
      variables: filters.date && {
        input: {
          startTime: filters.date.start,
          endTime: filters.date.end,
        },
      },
    });
  }, {
    data: path('oms.admin.report.slowHambsQueries', data),
    ...rest,
  }];
};
