import { useLazyQuery } from '@apollo/client';
import { omit } from 'lodash/fp';

import { isEmpty } from 'lodash';
import {useMemo, useRef, useState} from 'react';
import md5 from 'md5';

const getSearchId = input => {
    const relevantInput = omit('next', input);

    return md5(JSON.stringify(relevantInput));
};

export default (query, { nextLot: getNextLot, queryInput: getQueryInput, onCompleted } = {}) => {
    const [lots, setLots] = useState([undefined]);
    const [searchId, setSearchId] = useState();
    const [currentLotIndex, setCurrentLotIndex] = useState(0);
    const execFunctionInputRef = useRef();

    const [execute, { data, refetch, ...rest }] = useLazyQuery(query, {
        fetchPolicy: 'network-only',
        onCompleted: (loadedData) => {
            const nextLot = getNextLot(loadedData);
            if (nextLot) {
                const newLots = lots.concat(nextLot);
                setLots(Array.from(new Set(newLots)) || []);
            }
            if (onCompleted) {
                onCompleted(loadedData, execFunctionInputRef.current);
            }
        },
    });


    const getVariablesWhenNoFilters = (lotIndex) => ({
        input: {
            next: lots[lotIndex],
        },
    });

    const load = (filters = {}, lotIndex = currentLotIndex, otherOptions) => {
        const options = {
            variables: isEmpty(filters) ? getVariablesWhenNoFilters(lotIndex) : {
                input: {
                    ...getQueryInput(filters),
                    next: lots[lotIndex]
                },
            },
            ...otherOptions
        };


        const newSearchId = getSearchId(options?.variables?.input);

        if (newSearchId === searchId) {
            setCurrentLotIndex(lotIndex);
        } else {
            setLots([undefined]);
            setCurrentLotIndex(0);
        }
        setSearchId(newSearchId);
        execFunctionInputRef.current = options;
        execute(options);
    };



    const result = {
        data,
        lots,
        currentLotIndex,
        refetch: (arg) => {
            if (typeof arg === 'function') {
                const variables = arg(execFunctionInputRef?.current?.variables);
                execute({
                    ...execFunctionInputRef?.current,
                    variables
                });
            } else {
                refetch(arg);
            }
        },
        nextRef: getNextLot(data),
        ...rest,
    };

    console.log('PAGED QUERY', result);
    return [load, result];
};
