import {formatDate} from "../../common/dates";
import {sentenceCase} from "text-case";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import MemberAction from "./MemberAction";
import { ACTIONS } from "./constants";
import React from "react";
import styled from "styled-components";
import {Box} from "rebass/styled-components";
import {Typography} from "antd";

const { Text } = Typography;

const ActionsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 8px;
`;

export default ({ onAction, theme } = {}) => [
  {
    title: 'Member ID',
    dataIndex: 'membershipId',
    width: '7%',
  },
  {
    title: 'Person ID',
    dataIndex: 'id',
    width: '7%',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: '10%',
  },
  {
    title: 'Date Of Birth',
    dataIndex: 'dob',
    width: '10%',
    render: formatDate,
  },
  {
    title: 'Name',
    dataIndex: '',
    render: (_, member) => `${member.firstName} ${member.lastName}`,
    width: '15%',
  },
  {
    title: 'User Status',
    dataIndex: '',
    render: (_, member) => `${member.userStatus?.replace('_', ' ') || ''}`,
    width: '9%',
  },
  {
    title: 'Cover Status',
    dataIndex: '',
    width: '9%',
    render: (_, member) => (
      <Text
        type={['TERMINATED', 'UNKNOWN'].includes(member.coverStatus) ? 'danger' : ''}
      >
        {sentenceCase(member.coverStatus)}
      </Text>
    ),
  },
  {
    title: 'Online',
    dataIndex: 'onlineAccess',
    align: 'center',
    width: '2%',
    render: (_, member) => {
      const { onlineAccess } = member;

      return onlineAccess ? (
        <CheckCircleOutlined
          style={{
            color: theme?.colors?.green,
            fontSize: '17px',
          }}
          type="check-circle"
        />
      ) : (
        <CloseCircleOutlined
          style={{
            color: theme?.colors?.error,
            fontSize: '17px',
          }}
          type="close-circle"
        />
      );
    },
  }, {
    title: 'Email Verified',
    dataIndex: '',
    align: 'center',
    width: '2%',
    render: (_, member) => {
      const { emailVerified } = member;

      return emailVerified ? (
        <CheckCircleOutlined
          style={{
            color: theme?.colors?.green,
            fontSize: '17px',
          }}
          type="check-circle"
        />
      ) : (
        <CloseCircleOutlined
          style={{
            color: theme?.colors?.error,
            fontSize: '17px',
          }}
          type="close-circle"
        />
      );
    },
  },
  {
    title: 'MFA',
    dataIndex: '',
    width: '2%',
    align: 'center',
    render: (_, member) => {
      const { hasSmsMfa } = member;

      return hasSmsMfa ? (
        <CheckCircleOutlined
          style={{
            color: theme?.colors?.green,
            fontSize: '17px',
          }}
          type="check-circle"
        />
      ) : (
        <CloseCircleOutlined
          style={{
            color: theme?.colors?.error,
            fontSize: '17px',
          }}
          type="close-circle"
        />
      );
    },
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    width: '27%',
    render: (allowedActions, member) => (
      <ActionsWrapper>
        <MemberAction
          onClick={onAction}
          member={member}
          color="green"
          actions={allowedActions}
          id={ACTIONS.CHANGE_EMAIL}
        >
          Change contact details
        </MemberAction>
        <MemberAction
          onClick={onAction}
          member={member}
          color="blue"
          actions={allowedActions}
          id={ACTIONS.INVITE_MEMBER}
        >
          Invite
        </MemberAction>
        <MemberAction
          disabled={member.userStatus !== 'Enabled' ? 'Only allowed for enabled members' : ''}
          onClick={onAction}
          member={member}
          color="orange"
          actions={allowedActions}
          id={ACTIONS.RESET_PASSWORD}
        >
          Reset password
        </MemberAction>
        <MemberAction
          onClick={onAction}
          member={member}
          color="red"
          actions={allowedActions}
          id={ACTIONS.UNINVITE_MEMBER}
        >
          Uninvite
        </MemberAction>

        <MemberAction
          onClick={onAction}
          member={member}
          color="purple"
          actions={allowedActions}
          id={ACTIONS.UNLINK_AVANT}
        >
          Unlink Avant
        </MemberAction>

        <MemberAction
          onClick={onAction}
          member={member}
          color="purple"
          actions={allowedActions}
          id={ACTIONS.RESEND_EMAIL_CONFIRMATION}
        >
          Resend verification email
        </MemberAction>
        <MemberAction
          onClick={onAction}
          member={member}
          color="blue"
          actions={allowedActions}
          id={ACTIONS.TOGGLE_MFA}
        >
          {member.hasSmsMfa ? 'Disable MFA' : 'Enable MFA'}
        </MemberAction>
      </ActionsWrapper>
    ),
  },
];
