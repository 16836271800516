import React from 'react';
import { sentenceCase } from 'text-case';
import { Box } from 'rebass';
import { Divider } from 'antd';
import { path } from 'lodash/fp';
import FieldView from './FieldView';
import SectionTitle from './SectionTitle';

const getLabel = (name, data, allData, formatter) => {
  if (!formatter) {
    return sentenceCase(name);
  }

  if (typeof formatter === 'function') {
    return formatter(name, data, allData);
  }


  return formatter;
};

const formatBoolean = (value) => (value ? 'Yes' : 'No');

export default ({
  data, allData, fields, title, divider, ...props
}) => (
  <Box {...props}>
    {title && <SectionTitle>{title}</SectionTitle>}
    {Object.entries(data).map(([name, value]) => {
      if (name.startsWith('_') || path(name)(fields) === false) {
        return null;
      }

      const label = getLabel(name, data, allData, path(`${name}.label`)(fields));

      if (path(`${name}.value`)(fields)) {
        return <FieldView key={name} label={label}>{fields[name].value(value, data, allData)}</FieldView>;
      }


      if (typeof value === 'object' && value != null) {
        console.warn('Missing renderer for', name);
        return null;
      }

      if (typeof value === 'boolean') {
        // eslint-disable-next-line no-param-reassign
        value = formatBoolean(value);
      }


      return <FieldView key={name} label={label}>{value == null ? '-' : value}</FieldView>;
    })}
    {divider && <Divider />}
  </Box>
);
