import React from 'react';
import { Form, Input } from 'antd';
import FormModal from '../../common/molecules/FormModal';
import {email, mobileNumber, phoneNumber} from '../../common/validators';
import { getMemberFullName } from '../../common/util';

const ChangeEmailModal = ({
  form, member = {}, loading, ...props
}) => (
  <FormModal
    initialValues={{
      email: member.email,
      mobilePhone: member.mobilePhone,
      workPhone: member.workPhone,
      homePhone: member.homePhone
    }}
    loading={loading}
    form={form}
    title={`Change ${getMemberFullName(member)}'s contact details`}
    {...props}
  >
    <Form.Item
      name="email"
      label="Email to change"
      rules={[{
        required: true,
        message: 'email is required',
      }, {
        validator: email,
      }]}
    >
      <Input type="text" placeholder="email" />
    </Form.Item>
    <Form.Item
      name="mobilePhone"
      label="Mobile"
      rules={[{
        required: true,
        message: 'Mobile is required',
      }, {
        validator: mobileNumber,
      }]}
    >
      <Input type="text" placeholder="" />
    </Form.Item>
    <Form.Item
      name="homePhone"
      label="Home phone"
    >
      <Input type="text" placeholder="" />
    </Form.Item>
    <Form.Item
      name="workPhone"
      label="Work phone"
    >
      <Input type="text" placeholder="" />
    </Form.Item>
  </FormModal>
);

export default ChangeEmailModal;
