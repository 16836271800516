import React, {
  useState, createContext, useCallback, useEffect, useMemo,
} from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router';
import decodeJwt from 'jwt-decode';
import { path as lodashPath, pathOr, whereEq } from 'lodash/fp';
import { notification } from 'antd';
import routePaths from '../routing/routePaths';
import { useNotification } from '../common/providers/Notification';
import { USERS_GROUP_LIST } from '../common/constants';
import routeAccess from '../routing/routeAccess';

// Configure AWS Amplify
const AMPLIFY_CONFIG = {
  Auth: {
    region: process.env.DHF_AWS_COGNITO_REGION || process.env.AWS_REGION,
    userPoolId: process.env.DHF_AWS_USER_POOLS_ID,
    identityPoolId: process.env.DHF_AWS_IDENTITY_POOLS_ID,
    userPoolWebClientId: process.env.DHF_AWS_USER_POOLS_WEB_CLIENT_ID,
    ...(process.env.DHF_AWS_USER_POOL_OAUTH_DOMAIN ? {
      oauth: {
        domain: process.env.DHF_AWS_USER_POOL_OAUTH_DOMAIN,
        scope: process.env.DHF_AWS_USER_POOL_OAUTH_SCOPE?.split?.(',')?.map?.(x => x.trim())?.filter?.(Boolean) || [
          'phone',
          'email',
          'openid',
          'profile',
        ],
        redirectSignIn: process.env.DHF_AWS_USER_POOL_OAUTH_SIGN_IN_URL,
        redirectSignOut: process.env.DHF_AWS_USER_POOL_OAUTH_SIGN_OUT_URL,
        responseType: process.env.DHF_AWS_USER_POOL_OAUTH_RESPONSE_TYPE || 'code',
      }
    } : {}),
  },
};

const REMEMBER_USERNAME_KEY = 'rememberedUsername';

const handleRememberUsername = (formData) => {
  if (formData.rememberUsername) {
    localStorage.setItem(REMEMBER_USERNAME_KEY, formData.username);
  } else {
    localStorage.removeItem(REMEMBER_USERNAME_KEY);
  }
};

Amplify.configure(AMPLIFY_CONFIG);

const initialState = {
  userData: undefined,
  resetPasswordData: undefined,
  verificationData: undefined,
  error: undefined,
  loading: false,
  logout: () => {},
  login: () => {},
  ssoLogin: () => {},
};

const isAdmin = (token) => {
  const groups = pathOr([], 'cognito:groups')(token);
  return groups.some((group) => USERS_GROUP_LIST.find(whereEq({
    id: group,
  })));
};

const AuthContext = createContext(initialState);

const AuthProvider = ({ children, ...props }) => {
  const notification = useNotification();
  const history = useHistory();
  const [state, setState] = useState({});
  const changeState = (data) => {
    setState((currentState) => ({
      ...currentState,
      ...data,
    }));
  };

  const client = useApolloClient();

  // Checks if the user is logged in
  const isLoggedIn = useMemo(() => !!(state.userData && state.userData.username), [state.userData]);


  // Get the current authenticated user
  const getUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user || undefined;
    } catch (err) {
      return undefined;
    }
  };

  // Load the initial user
  useEffect(() => {
    changeState({
      gettingUser: true,
    });
    getUser()
      .then((userData) => {
        changeState({ userData, gettingUser: false });
      })
      .catch((error) => changeState({ error, userData: null }));
  }, []);


  // Get the access token from the current session
  const getToken = async () => {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  };

  const getDecodedToken = async () => {
    const token = await getToken();
    return decodeJwt(token);
  };


  // Logout and redirect to the login page
  const logout = useCallback(
    async () => {
      changeState({
        userData: undefined,
        resetPasswordData: undefined,
        verificationData: undefined,
        error: undefined,
        loading: false,
      });

      await Auth.signOut();
      await client.clearStore();
    },
    [client],
  );

  // Checks for token and logs out if not found
  const checkForToken = useCallback(async () => {
    const token = await getToken();

    if (!token) {
      await logout();
    }
  }, [logout]);

  const login = async ({ username, password, rememberUsername }) => {
    changeState({
      loading: true,
      error: undefined,
    });

    try {
      const user = await Auth.signIn({
        username: username.toLowerCase(),
        password,
      });

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        changeState({
          loading: false,
        });
        history.push('/changePassword', {
          newPasswordRequired: true,
          oldPassword: password,
          username: username.toLowerCase(),
        });
        return;
      }

      const token = await getDecodedToken();

      if (!isAdmin(token)) {
        throw new Error('Incorrect username or password');
      }

      handleRememberUsername({ username, rememberUsername });
      changeState({
        loading: false,
        userData: user,
      });

      history.push(routePaths.dashboard);
    } catch (error) {
      changeState({
        loading: false,
        error: error.message || error,
      });
    }
  };

  const ssoLogin = async () => {
    changeState({
      loading: true,
      error: undefined,
    });

    try {
      await Auth.federatedSignIn({ customProvider: process.env.DHF_AWS_USER_POOL_OAUTH_PROVIDER || undefined });

      changeState({ loading: false });

      history.push(routePaths.dashboard);
    } catch (error) {
      changeState({
        loading: false,
        error: error.message || error,
      });
    }
  };


  const forgotPassword = async (username) => {
    try {
      changeState({
        loading: true,
        error: undefined,
      });

      // check if user entered a number (potential member number) HAMBS
      const data = await Auth.forgotPassword(username);

      changeState({
        forgotPasswordData: data,
        loading: false,
      });
    } catch (error) {
      changeState({
        error,
        loading: false,
      });
    }
  };

  const changePasswordForNewUser = async ({ oldPassword, username, newPassword }) => {
    try {
      changeState({
        loading: true,
      });
      const user = await Auth.signIn(username, oldPassword);
      await Auth.completeNewPassword(user, newPassword);
      const loggedUser = await Auth.signIn(username, newPassword);
      notification.success({
        duration: 5,
        message: 'Password changed',
        description: 'You have successfully changed your temporary password.',
      });
      history.push(routePaths.dashboard);
      changeState({
        loading: false,
        userData: loggedUser,
      });
    } catch (error) {
      changeState({
        loading: false,
        error: error.message || error,
      });
    }
  };


  const changePassword = useCallback(
    async ({ oldPassword, newPassword }) => {
      changeState({
        loading: true,
      });
      try {
        const user = await Auth.currentAuthenticatedUser();

        await Auth.changePassword(user, oldPassword, newPassword);

        notification.success({
          duration: 5,
          message: 'Password changed',
          description: 'You have successfully changed your password. Please login with new password next time you log in.',
        });
        history.push(routePaths.login);
        changeState({
          loading: false,
        });
      } catch (error) {
        changeState({
          loading: false,
          error: error.message || error,
        });
      }
    },
    [],
  );


  const resetFormState = () => {
    changeState((currentState) => ({
      ...currentState,
      error: undefined,
      loading: false,
    }));
  };

  const getCurrentCredentials = () => Auth.currentCredentials();

  const rememberedUsername = localStorage.getItem(REMEMBER_USERNAME_KEY);

  const canAccess = (allowedGroups) => {
    if (!allowedGroups || !allowedGroups.length) {
      return true;
    }
    const currentUserGroups = lodashPath('userData.signInUserSession.idToken.payload.cognito:groups', state) || [];
    return !!currentUserGroups.length && currentUserGroups.some((group) => {
      return allowedGroups.find(allowedGroup => allowedGroup === group || lodashPath('id', allowedGroup) === group);
    });
  };


  return (
    <AuthContext.Provider
      value={{
        ...state,
        rememberedUsername,
        login,
        ssoLogin,
        logout,
        getToken,
        getUser,
        checkForToken,
        forgotPassword,
        resetFormState,
        isLoggedIn,
        changePassword,
        getCurrentCredentials,
        changePasswordForNewUser,
        canAccess,
      }}
      {...props}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
