import {  useMutation } from '@apollo/client';
import AdminVerifyEmailMutation from '../../../queries/adminVerifyEmailMutation.graphql';

export default () => {
  const [mutation, ...rest] = useMutation(AdminVerifyEmailMutation);

  return [(email) => mutation({
    variables: {
      input: {
        email,
      },
    },
  }), ...rest];
};
