import { identity } from 'lodash';
import { Parser } from 'json2csv';
import { downloadTextFile } from '../util';
import useToggle from './useToggle';

const toCsv = ({
  dataSource, columns, name = 'unknown', ...options
}) => {
  const { transformers, fields, ...restOptions } = options;
  const columnFormatters = columns ? columns.reduce((acc, column) => ({
    ...acc,
    [column.dataIndex]: column.formatCsv || identity,
  }), {}) : undefined;

  const parser = new Parser({
    delimiter: ',',
    excelStrings: true,
    header: true,
    transforms: transformers || [(data) => {
      if (columnFormatters) {
        return Object.entries(data).reduce((acc, [fieldName, fieldValue]) => {
          const format = columnFormatters[fieldName];
          return {
            ...acc,
            [data.title]: format ? format(fieldValue, data) : fieldValue,
          };
        }, {});
      }

      return data;
    }],
    ...restOptions,
    fields: fields || columns.map((column) => column.dataIndex),
  });
  const csv = parser.parse(dataSource);
  downloadTextFile(`${name}.csv`, csv);
};

export default ({
  dataSource = [], name, columns, options = {},
}) => {
  const [menuVisible, toggleMenu, _, hideMenu] = useToggle(false);


  const exportToCsv = (redefinedOptions) => {
    toCsv({
      dataSource,
      name,
      columns,
      ...options,
      ...redefinedOptions,
    });
    hideMenu();
  };

  return {
    state: {
      menuVisible,
    },
    actions: {
      toggleMenu,
      hideMenu,
      exportToCsv,
    },
  };
};
