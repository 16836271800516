import React from 'react';
import { Flex } from 'rebass';
import MedicareRebatesTable from './MedicareRebatesTable';
import useMedicareRebates from './useMedicareRebates';
import MedicareRebatesFilters from './MedicareRebatesFilters';

const MedicareRebates = () => {
  const medicareRebates = useMedicareRebates();
  return (
    <>
      <Flex flexDirection="column" my={3}>
        <MedicareRebatesFilters
          loading={medicareRebates.state.loading}
          form={medicareRebates.state.filtersForm}
          onSearch={medicareRebates.actions.search}
        />
      </Flex>
      <MedicareRebatesTable
        lots={medicareRebates.state.lots}
        currentLotIndex={medicareRebates.state.currentLotIndex}
        onLotChange={medicareRebates.actions.changeLot}
        loading={medicareRebates.state.loading}
        logs={medicareRebates.state.list}
      />
    </>
  );
};

export default MedicareRebates;
