import React from 'react';
import { Collapse } from 'antd';
import handlers from './viewHandlers';
import FieldsView from './FieldsView';

const { Panel } = Collapse;

const Viewer = ({ form, quoteInput }) => {
  const data = {
    ...form,
    quoteInput,
  };

  console.log('data', data);

  console.log('handlers', handlers);
  return (
    <Collapse defaultActiveKey={['quoteInput']}>

      {handlers.map(({ id, handler }) => (
        data[id] ? (
          <Panel header={handler.title} key={id}>
            {handler.customRender
              ? handler.customRender({ data: data[id], allData: data })
              : <FieldsView data={data[id]} allData={data} fields={handler.fields} />}
          </Panel>
        ) : null
      ))}
    </Collapse>
  );
};

export default Viewer;
