import React from 'react';
import { Flex, Box } from 'rebass';
import { whereEq } from 'lodash/fp';
import { DeleteOutlined, EditOutlined, LockOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Button from '../common/atoms/Button';
import { USERS_GROUP_LIST } from '../common/constants';
import { formatDate } from '../common/dates';
import Table from '../common/organisms/Table';
import routePaths from '../routing/routePaths';

const groupInUsersGroupList = groups =>
  groups?.some?.(group => USERS_GROUP_LIST.find(whereEq({ id: group })));

const UsersTable = ({
  loading, users, pagination, onChange, onDelete, onEdit, onPasswordReset,
}) => {
  const history = useHistory();
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'given_name',
      width: '15%',
      sort: 'string',
    },
    {
      title: 'Last Name',
      dataIndex: 'family_name',
      width: '15%',
      sort: 'string',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '22%',
      sort: 'string',

    },
    {
      title: 'Group',
      dataIndex: 'group',
      width: '10%',
      sort: 'string',

      render: (group) => (
        <span>
          {USERS_GROUP_LIST.find(whereEq({
            id: group,
          }))?.label || ''}
        </span>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'UserCreateDate',
      width: '10%',
      render: formatDate,
      sort: 'date',
    },
    {
      title: 'Status',
      dataIndex: 'UserStatus',
      width: 'auto',
      sort: 'string',
      render: (status) => status.replace(/_/g, ' '),
    },
    {
      align: 'right',
      title: 'Actions',
      fixed: 'right',
      width: '10%',
      dataIndex: 'actions',
      render: (_, user) => (
        <Flex justifyContent="flex-end">
          <Box mr={2}>
            <Button
              disabled={user.isCurrentUser || !groupInUsersGroupList([user.group])}
              tooltip={
                user.isCurrentUser
                  ? 'You are not allowed to delete yourself!'
                  : !groupInUsersGroupList([user.group])
                    ? `Cannot delete a user that's not in an admin group!`
                    : 'Delete user'
              }
              onClick={() => {
                onDelete(user);
              }}
              icon={<DeleteOutlined />}
            />
          </Box>
          <Box mr={2}>
            <Button
              tooltip="Edit user"
              onClick={() => {
                onEdit(user);
              }}
              icon={<EditOutlined />}
            />
          </Box>
          <Box>
            <Button
              disabled={!user.Enabled || !['CONFIRMED', 'FORCE_CHANGE_PASSWORD'].includes(user.UserStatus)}
              tooltip="Reset Password"
              onClick={() => (
                user.isCurrentUser ? history.push(routePaths.changePassword) : onPasswordReset(user)
              )}
              icon={<LockOutlined />}
            />
          </Box>
        </Flex>
      ),
    },
  ];

  return (
    <Table
      scroll={{
        x: 1300,
      }}
      rowKey={(user) => user.email}
      loading={loading}
      onChange={onChange}
      pagination={pagination}
      columns={columns}
      dataSource={users}
    />
  );
};

export default UsersTable;
