import {
  useContext,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Modal } from 'antd';
import {
  pipe, some, pathEq, pathOr, path,
} from 'lodash/fp';
import useToggle from '../../../common/hooks/useToggle';
import { useNotification } from '../../../common/providers/Notification';
import { getMemberFullName, noop } from '../../../common/util';
import { ACTIONS } from '../constants';
import useMembersQuery from './useMembersQuery';
import useInviteMember from './useInviteMember';
import useUpdateMember from './useUpdateMember';
import useAuth from '../../../auth/useAuth';
import useUninviteMember from './useUninviteMember';
import useUnconvertMember from './useUnconvertMember';
import { actionsApplicability } from '../actionsApplicability';
import useActionLoader from './useActionLoader';
import useAvantLinkage from './useAvantLinkage';
import useHambsPassword from './useHambsPassword';
import { AuthContext } from '../../../auth/AuthContext';
import { USER_GROUPS } from '../../../common/constants';
import useVerifyEmail from './useVerifyEmail';
import { isUnauthenticatedMembership } from '../../../auth/AuthUtils';
import useSetMFA from "./useSetMFA";

export default () => {
  const [modalOpen, _, showModal, hideModal] = useToggle();
  const [activeAction, setActiveAction] = useState();
  const [activeMember, setActiveMember] = useState();
  const notification = useNotification();
  const [error, setError] = useState();

  const [getMembers, {
    data: membersData, loading: membersLoading, refetch: refetchMembers, error: fetchingMembersError,
  }] = useMembersQuery();
  const { members } = membersData;
  const [requestMemberInvitation, { loading: invitingMember }] = useInviteMember();
  const [updateMember, { loading: updatingMemberDetails }] = useUpdateMember();
  const { forgotPassword, loading: sendingResetPasswordRequest, error: authError } = useAuth();
  const [uninviteMutation, { loading: uninvitingMember }] = useUninviteMember();
  const [unconvertMutation, { loading: unconverting }] = useUnconvertMember();
  const [resetHAMBSPassword, syncHAMBSPassword, { loading: resetting }] = useHambsPassword();
  const [sendVerificationEmail, { loading: sendingVerificationEmail }] = useVerifyEmail();
  const [setMFa, { loading: settingMFA }] = useSetMFA();
  const { unlink } = useAvantLinkage();
  const { canAccess } = useContext(AuthContext);
  const [filteredMembershipId, setFitleredMembershipId] = useState();

  const [avantUnlink] = unlink;
  const { getLoader, setLoader } = useActionLoader();

  const isResetHambsPasswordAllowed = canAccess([USER_GROUPS.ADMIN.id, USER_GROUPS.SYSTEM.id]);
  const membershipId = membersData.membershipId || filteredMembershipId;

  const actionCreator = ({
    action, member, notify, notifyError = noop,
  }) => async (...args) => {
    const currentMember = member || activeMember;
    try {
      setLoader(currentMember, true);
      const result = await action(...args);
      hideModal();
      notification.success({
        duration: 0, // don't close the popup automatically
        ...notify(...args, currentMember),
      });
      setLoader(currentMember, false);
      return result;
    } catch (err) {
      console.error(err);
      notification.error({
        duration: 0,
        message: 'Unexpected error!',
        description: err.message || err,
        ...notifyError(err, currentMember),
      });
      setError(err.message || err);
      setLoader(currentMember, false);
    }

    return undefined;
  };

  const inviteMember = actionCreator({
    action: async (formData) => {
      const result = await requestMemberInvitation(membershipId, {
        id: activeMember.id,
        ...formData,
      });
      if (!result.data.adminInvitePerson.success) {
        throw new Error(result.data.adminInvitePerson.message);
      }

      return result;
    },
    notify: (formData, member) => ({
      message: `Invitation sent to ${getMemberFullName(member)}'s email on ${formData.email}.`,
    }),
  });


  const changeEmail = actionCreator({
    action: async (formData) => {
      const result = await updateMember(membershipId, {
        id: activeMember.id,
        email: formData.email,
        homePhone: formData?.homePhone || null,
        mobilePhone: formData.mobilePhone,
        workPhone: formData?.workPhone || null,
      });
      if (!result.data.adminUpdatePerson.success) {
        throw new Error(result.data.adminUpdatePerson.message);
      }
      return result;
    },
    notify: (formData, member) => ({
      message: `Contact details have been successfully updated for ${getMemberFullName(member)}.`,
    }),
  });


  const uninviteMember = (member) => {
    Modal.confirm({
      title: `Do you want to uninvite ${getMemberFullName(member)}`,
      content: 'By clicking yes you agree with uninviting this user from the OMS portal. Are you sure you want to do this?',
      onOk: actionCreator({
        member,
        action: async () => {
          const response = await uninviteMutation(membershipId, member);
          if (!response.data.adminUninvitePerson.success) {
            throw response.data.adminUninvitePerson.message;
          }

          return response;
        },
        notify: () => ({
          message: `You have successfully uninvited ${getMemberFullName(member)} from OMS portal`,
        }),
      }),
    });
  };

  const resetPassword = (member) => {
    Modal.confirm({
      title: `Do you want to reset password for ${getMemberFullName(member)}?`,
      content: `By clicking yes the reset password email is going to be sent to ${getMemberFullName(member)} with all the instruction how to proceed further?`,
      onOk: actionCreator({
        member,
        action: () => forgotPassword(member.email),
        notify: () => ({
          message: `You have successfully sent a reset password email to ${member.email}`,
        }),
      }),
    });
  };

  const unconvertMembership = (id) => {
    Modal.confirm({
      title: 'You are about to unconvert membership?',
      content: `Are you sure you want to unconvert currently selected membership ${id}?`,
      okType: 'danger',
      cancelTet: 'No',
      onOk: actionCreator({
        action: async () => {
          const result = await unconvertMutation(id, members);
          if (!result.data.adminUnconvertMembership.success) {
            throw result.data.adminUnconvertMembership.message;
          }

          return result;
        },
        notify: () => ({
          message: `You have successfully unconverted membership ${id}.`,
        }),
      }),
    });
  };

  const unlinkAvantAction = (member) => {
    Modal.confirm({
      title: 'You are about to unlink the person from avant membership?',
      content: `Are you sure you want to unlink ${getMemberFullName(member)} from avant?`,
      okType: 'danger',
      onOk: actionCreator({
        member,
        action: async () => {
          const result = await avantUnlink(member);
          if (!result.data.adminAvantUnlinkPerson.success) {
            throw result.data.adminAvantUnlinkPerson.message;
          }

          return result;
        },
        notify: () => ({
          message: `You have successfully unlinked ${getMemberFullName(member)} from avant.`,
        }),
      }),
    });
  };

  const resetHambsPasswordAction = (member) => {
    Modal.confirm({
      title: 'You are about to reset HAMBS password',
      content: `Are you sure you want to reset HAMBS password for ${membershipId} membership?`,
      onOk: actionCreator({
        member,
        action: async () => {
          const result = await resetHAMBSPassword(membershipId);
          if (!result.data.adminSetHambsPassword.success) {
            throw result.data.adminSetHambsPassword.message;
          }

          return result;
        },
        notify: () => ({
          message: `You have successfully sent the request for reset HAMBS password for ${membershipId} membership. Please keep in mind this process can take up to 5 minutes.`,
        }),
        notifyError: (error) => ({
          description: `We are sorry. We were unable to reset HAMBS password. Please contact our support.
          Error: ${error}`,
          message: 'Resetting HAMBS password failed',
        }),
      }),

    });
  };

  const syncHAMBSPasswordAction = (member) => {
    Modal.confirm({
      title: 'You are about to sync HAMBS password',
      content: `Are you sure you want to sync HAMBS password for ${membershipId} membership?`,
      onOk: actionCreator({
        member,
        action: async () => {
          const result = await syncHAMBSPassword(membershipId);
          if (!result.data.adminSetHambsPassword.success) {
            throw result.data.adminSetHambsPassword.message;
          }

          return result;
        },
        notify: () => ({
          message: `You have successfully sent the request for sync HAMBS password for ${membershipId} membership. Please keep in mind this process can take up to 5 minutes.`,
        }),
        notifyError: (error) => ({
          description: `We are sorry. We were unable to sync HAMBS password. Please contact our support.
          Error: ${error}`,
          message: 'Syncing HAMBS password failed',
        }),
      }),

    });
  };

  const resendEmailConfirmationAction = (member) => {
    Modal.confirm({
      title: 'Resend Confirmation Email',
      content: `Are you sure you want to resend confirmation email to ${getMemberFullName(member)}?`,
      onOk: actionCreator({
        member,
        action: async () => {
          const result = await sendVerificationEmail(member.email);
          if (!result.data.adminVerifyEmail.success) {
            throw result.data.adminVerifyEmail.message;
          }

          return result;
        },
        notify: () => ({
          message: `You have successfully resent the confirmation email to ${getMemberFullName(member)}.`,
        }),
        notifyError: (error) => ({
          description: `We are sorry. We were unable to resend confirmation email.
          Error: ${error}`,
          message: 'Resending confirmation email password failed',
        }),
      }),
    });
  };

  const toggleMFA = (member) => {
    Modal.confirm({
      title: member.hasSmsMfa ? 'Disable MFA' : 'Enable MFA',
      content: `Are you sure you want to ${member.hasSmsMfa ? 'disable' : 'enable'} MFA for ${getMemberFullName(member)}?`,
      onOk: actionCreator({
        member,
        action: async () => {
          const result = await setMFa(member, {
            sms: {
              enabled: !member.hasSmsMfa,
              get preferred() {
                return this.enabled;
              }
            }
          });
          if (!result.data.adminSetMFA.success) {
            throw result.data.adminSetMFA.message;
          }

          return result;
        },
        notify: () => ({
          message: `You have successfully ${member.hasSmsMfa ? 'disabled' : 'enabled'} MFA for ${getMemberFullName(member)}.`,
        }),
        notifyError: (error) => ({
          description: `We are sorry. We were unable to ${member.hasSmsMfa ? 'disabled' : 'enabled'}} MFA.
          Error: ${error}`,
          message: 'Toggle MFA failed',
        }),
      })
    });
  };

  const onActionClickHandlers = {
    [ACTIONS.INVITE_MEMBER]: showModal,
    [ACTIONS.CHANGE_EMAIL]: showModal,
    [ACTIONS.TOGGLE_MFA]: toggleMFA,
    [ACTIONS.UNINVITE_MEMBER]: uninviteMember,
    [ACTIONS.RESET_PASSWORD]: resetPassword,
    [ACTIONS.UNCONVERT_MEMBERSHIP]: () => unconvertMembership(membershipId),
    [ACTIONS.UNLINK_AVANT]: unlinkAvantAction,
    [ACTIONS.RESET_HAMBS_PASSWORD]: resetHambsPasswordAction,
    [ACTIONS.SYNC_HAMBS_PASSWORD]: syncHAMBSPasswordAction,
    [ACTIONS.RESEND_EMAIL_CONFIRMATION]: resendEmailConfirmationAction,
  };

  const openActionModal = (action, member) => {
    setActiveAction(action);
    setActiveMember(member);
    onActionClickHandlers[action](member);
  };

  const loadMembers = ({ memberId, email }) => {
    setFitleredMembershipId(memberId);
    getMembers({ memberId, email });
  };


  const unconvertAvailable = (() => {
    if (!members || !members.length || membersLoading) {
      return false;
    }

    return actionsApplicability[ACTIONS.UNCONVERT_MEMBERSHIP](members);
  })();

  const hambsPasswordOutOfSyncAlert = useMemo(() => {
    if (!fetchingMembersError) {
      return;
    }

    const isOutOfSync = pipe(
      pathOr([], ['graphQLErrors']),
      some(isUnauthenticatedMembership),
    )(fetchingMembersError);

    if (isOutOfSync) {
      return {
        type: 'warning',
        message: 'HAMBS password out of sync!',
        description: isResetHambsPasswordAllowed
          ? 'HAMBS password is out of sync. In order to solve this please use Reset/Sync HAMBS password buttons in the right upper corner.'
          : 'The HAMBS password is out of sync but you don\'t have enough privileges to act on this. Please contact system or admin users in order to sort out this!',
      };
    }
  }, [fetchingMembersError]);

  if (process.env.NODE_ENV !== 'production') {
    console.log('MEMBERS:', members);
    console.log('MEMBERSHIP_ID', membershipId);
  }

  return {
    state: {
      hambsPasswordOutOfSyncAlert,
      modalOpen,
      unconverting,
      error,
      activeAction,
      activeMember,
      members,
      membersLoading,
      unconvertAvailable,
      executingAction: getLoader(activeMember),
      pagination: false,
      isResetHambsPasswordAllowed,
    },
    actions: {
      loadMembers,
      hideActionModal: hideModal,
      openActionModal,
      changeEmail,
      inviteMember,
    },
  };
};
