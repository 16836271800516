import React from 'react';
import styled from 'styled-components';
import { useTheme } from '../theme';

const StyledMethod = styled.span`
  color: ${(props) => props.color};
  font-weight: 700;
`;


const Method = ({ value }) => {
  const theme = useTheme();
  const color = {
    get: theme.colors.palette.green[5],
    post: theme.colors.palette.orange[5],
    put: theme.colors.palette.blue[5],
    delete: theme.colors.palette.red[5],
  }[value.toLowerCase()];

  return (
    <StyledMethod color={color}>
      {value}
    </StyledMethod>
  );
};

export default Method;
