/* eslint-disable no-unused-vars, no-param-reassign, no-console, no-shadow */
import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';
import { path, whereEq } from 'lodash/fp';
import useToggle from '../common/hooks/useToggle';
import useAuth from '../auth/useAuth';
import { FORM_MODES, USERS_GROUP_LIST } from '../common/constants';
import { useNotification } from '../common/providers/Notification';
import useCognito from './useCognito';
import { containsCaseInsensitive } from '../common/util';
import useResetAdminPassword from './useResetAdminPassword';

const getUserAttributesPayload = (form) => [{
  Name: 'email',
  Value: form.email.toLowerCase(),
}, {
  Name: 'email_verified',
  Value: 'true',
}, {
  Name: 'family_name',
  Value: form.family_name,
}, {
  Name: 'given_name',
  Value: form.given_name,
}];

const getCreateUserPayload = (form) => ({
  Username: form.email.toLowerCase(),
  DesiredDeliveryMediums: ['EMAIL'],
  UserAttributes: getUserAttributesPayload(form),
  ClientMetadata: {
    clientId: process.env.DHF_AWS_USER_POOLS_WEB_CLIENT_ID || 'default',
  },
});

const getEditUserPayload = (form, user) => ({
  Username: user.Username,
  UserAttributes: getUserAttributesPayload(form),
});

const mapAttributeValue = (value) => {
  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return value;
};

export default () => {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [usersListError, setUsersListError] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [modalLoading, setModalLoading] = useState(false);
  const [isManageUserModalVisible, , openManageUserModal, hideManageUserModal] = useToggle();
  const [manageUserError, setManageUserError] = useState();
  const [formMode, setFormMode] = useState(FORM_MODES.ADD);
  const [tableState, setTableState] = useState();
  const notification = useNotification();
  const auth = useAuth();
  const { getCognitoISP } = useCognito();
  const [filters, setFilters] = useState();
  const [resetAdminPassword] = useResetAdminPassword();

  const mapUser = (group = {}) => (user) => ({
    ...user,
    group: typeof group === 'string' ? group : group.id,
    isCurrentUser: path('userData.username', auth) === user.Username,
    ...(user.Attributes.reduce((acc, attribute) => {
      acc[attribute.Name] = mapAttributeValue(attribute.Value);
      return acc;
    }, {})),
  });

  const loadUsersForGroup = (isp, currentUsers = []) => (group) => isp.listUsersInGroup({
    GroupName: group.id,
    Limit: 60,
  }).then(({ Users, NextToken }) => {
    let mappedUsers = Users.map(mapUser(group));

    mappedUsers = currentUsers.concat(mappedUsers);


    return NextToken ? loadUsersForGroup(isp, mappedUsers)(group) : mappedUsers;
  });

  const loadUsers = (filters) => {
    setUsersLoading(true);
    getCognitoISP()
      .then((isp) => Promise.all([
        ...USERS_GROUP_LIST,
        // Get users for external provider group so users can be added to an admin group.
        ...(process.env.DHF_AWS_USER_POOL_OAUTH_GROUP
          ? [{ id: process.env.DHF_AWS_USER_POOL_OAUTH_GROUP, label: '' }]
          : []
        )].map(loadUsersForGroup(isp))))
      .then((usersPerGroup) =>
        usersPerGroup.reduce(
          (acc, users, index) =>
            acc.concat(
              index !== USERS_GROUP_LIST.length
                ? users
                // Don't display the same user if they have been added to an admin group.
                : users.filter(user => !acc.find(u => u.sub === user.sub))
            ),
          []
        )
      )
      .then((users) => {
        setUsersLoading(false);
        setUsers(users);
      })
      .catch((error) => {
        setUsersLoading(false);
        setUsersListError(error.message || error);
        console.error(error);
      });
  };

  const openAddUserModal = () => {
    openManageUserModal();
    setSelectedUser();
    setFormMode(FORM_MODES.ADD);
  };

  const openEditUserModal = (user) => {
    setSelectedUser(user);
    setFormMode(FORM_MODES.EDIT);
    openManageUserModal();
  };

  const openDeleteUserModal = (user) => {
    Modal.confirm({
      title: `Delete ${user.given_name} ${user.family_name} from the system`,
      content: 'Are you sure you want to delete this user from the system permanently?',
      onOk: () => getCognitoISP().then((isp) => isp.adminRemoveUserFromGroup({
        Username: user.Username,
        GroupName: user.group,
      })).then(() => {
        loadUsers();
        notification.success({
          message: 'User deleted',
          description: 'You have successfully deleted user from the system!',
        });
      }).catch((error) => {
        notification.error({
          message: 'Error while deleting user',
          description: error.message || error,
        });
      }),
      okType: 'danger',
    });
  };

  const openResetPasswordModal = (user) => {
    Modal.confirm({
      title: `Reset password for ${user.given_name} ${user.family_name}`,
      content: 'Are you sure you want to reset password?',
      onOk: () => resetAdminPassword(user.email).then((result) => {
        if (!result.data.adminResetAdminPassword.success) {
          throw result.data.adminResetAdminPassword.message;
        }
        loadUsers();
        notification.success({
          message: 'Password Reset',
          description: 'You have successfully reset and sent a temporary password to requested user',
        });
      }).catch((error) => {
        notification.error({
          message: 'Error while resetting password for user',
          description: error.message || error,
        });
      }),
      okType: 'danger',
    });
  };

  const editUserBase = (form, user) => {
    const payload = getEditUserPayload(form, user);
    return getCognitoISP()
      .then((isp) => isp.adminUpdateUserAttributes(payload).then(async () => {
        if (USERS_GROUP_LIST.find(whereEq({ id: user.group }))) {
          await isp.adminRemoveUserFromGroup({
            Username: user.Username,
            GroupName: user.group,
          });
        }
        return isp.adminAddUserToGroup({
          Username: user.Username,
          GroupName: form.group,
        });
      }));
  };

  const createUser = async (form) => {
    setModalLoading(true);
    setManageUserError(undefined);
    const payload = getCreateUserPayload(form);

    return getCognitoISP()
      .then((isp) => isp.adminCreateUser(payload)
        .then(({ User }) => isp.adminAddUserToGroup({
          Username: User.Username,
          GroupName: form.group,
        }))
        .catch(async (error) => {
          if (error.code === 'UsernameExistsException' && !users.find(whereEq({
            email: form.email,
          }))) {
            const user = await isp.getUserByEmail(form.email);

            const mappedUser = mapUser(form.group)(user);

            setSelectedUser(mappedUser);
            return editUserBase(form, mappedUser);
          }

          return Promise.reject(error);
        })
        .then(() => {
          notification.success({
            message: 'New User Added',
            description: 'You have successfully created new user!',
          });
          setModalLoading(false);
          hideManageUserModal();
          loadUsers();
        }))
      .catch((error) => {
        setModalLoading(false);
        setManageUserError(error.message || error);
        console.error(error);
      });
  };

  const editUser = (form, user) => {
    user = user || selectedUser;
    setManageUserError(undefined);
    setModalLoading(true);
    return editUserBase(form, user)
      .then(() => {
        setModalLoading(false);
        notification.success({
          message: 'User edited',
          description: `You have successfully edited ${user.email}`,
        });
        hideManageUserModal();
        loadUsers();
        setSelectedUser(undefined);
      }).catch((error) => {
        setModalLoading(false);
        setManageUserError(error.message || error);
        console.error(error);
      });
  };

  const handleManageModalOk = (form) => {
    switch (formMode) {
      case FORM_MODES.ADD: {
        return createUser(form);
      }

      case FORM_MODES.EDIT: {
        return editUser(form);
      }

      default:
        throw new Error(`Invalid form mode of "${formMode}"`);
    }
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableState({
      pagination,
      filters,
      sorter,
    });
  };

  const searchUsers = (filters) => {
    setFilters(filters);
  };

  const filteredUsers = useMemo(() => {
    if (!users || !filters) {
      return users;
    }

    const { name, email } = filters;

    if (!name && !email) {
      return users;
    }

    return users.filter((user) => email ?  email === user.email : true &&
        (containsCaseInsensitive(user.given_name, name) || containsCaseInsensitive(user.family_name, name)));
  }, [users, filters]);


  return {
    state: {
      formMode,
      isManageUserModalVisible,
      selectedUser,
      users: filteredUsers,
      usersLoading,
      modalLoading,
      manageUserError,
      tableState,
    },
    actions: {
      openAddUserModal,
      openDeleteUserModal,
      openEditUserModal,
      openResetPasswordModal,
      hideManageUserModal,
      handleManageModalOk,
      handleTableChange,
      searchUsers,
    },
  };
};
