import identity from 'lodash/identity';
import includes from 'lodash/includes';
import React from 'react';
import { Box, Flex } from 'rebass';
import { Tag } from 'antd';

import LogsTable from '../../common/organisms/LogsTable';
import { formatDate, formatDateTime } from '../../common/dates';

const FormatBoolean = ({ value } = {}) => {
  if (value) {
    return 'Yes';
  }
  if (includes(['string', 'boolean', 'number'], typeof value)) {
    return 'No';
  }
  return '?';
};

const SugarCrmStatus = ({ value } = {}) => {
  const status = String(value).toLowerCase();
  let color;

  if (status === 'success') {
    color = 'green';
  } else if (status === 'error') {
    color = 'red';
  } else if (status === 'incomplete') {
    color = 'orange';
  } else if (!status || status === 'unknown') {
    color = 'gray';
  } else {
    color = 'pink';
  }

  return <Tag color={color}>{status}</Tag>;
};

const joinName = ({ firstName, middleName, lastName } = {}) => [firstName, middleName, lastName].filter(identity).join(' ');

const joinAddress = ({
  line1,
  line2,
  line3,
  suburb,
  state,
  postcode,
  country,
} = {}) => [line1, line2, line3, suburb, postcode, state, country !== 'Australia' ? country : ''].filter(identity).join(', ');

export const medicareRebatesColumns = [
  {
    title: 'Submitted',
    dataIndex: 'timestamp',
    width: '8%',
    sort: 'date',
    fixed: 'left',
    render: formatDateTime,
  },
  {
    title: 'SugarCRM Details',
    dataIndex: '',
    width: '20%',
    fixed: 'left',
    sort: 'string',
    render: (_, { id, reference, status } = {}) => (
      <Flex flexWrap="wrap">
        <Box hidden={!id} width={1}>
          ID:
          { ` ${id}` }
        </Box>
        <Box hidden={!reference} width={1}>
          Reference:
          { ` ${reference}` }
        </Box>
        <Box width={1}>
          Status:
          { ' ' }
          <SugarCrmStatus value={status} />
        </Box>
      </Flex>
    ),
  },
  {
    title: 'People on Policy',
    dataIndex: '',
    width: '20%',
    fixed: 'left',
    render: (_, { phoneNumber, residentialAddress, peopleOnPolicy = [] } = {}) => (
      <Flex flexWrap="wrap">
        {peopleOnPolicy.filter(
          (person) => person && person.id,
        ).map((person) => (
          <Box key={person.id} width={1} paddingBottom={1}>
            <Box width={1}>
              Full name:
              {` ${joinName(person)}`}
            </Box>
            <Box hidden={person.relationship === 'Membr'} width={1}>
              Relationship:
              {` ${person.relationship}`}
            </Box>
            <Box width={1}>
              Date of birth:
              {` ${formatDate(person.dob)}`}
            </Box>
            <Box hidden={person.relationship !== 'Membr'} width={1}>
              Gender:
              {` ${person.gender}`}
            </Box>
            <Box hidden={person.relationship !== 'Membr'} width={1}>
              Contact phone:
              {` ${phoneNumber}`}
            </Box>
            <Box hidden={person.relationship !== 'Membr'} width={1}>
              Residential address:
              {` ${joinAddress(residentialAddress)}`}
            </Box>
          </Box>
        ))}
      </Flex>
    ),
  },
  {
    title: 'Medicare Card Details',
    dataIndex: 'medicare',
    width: '10%',
    fixed: 'left',
    render: ({
      firstName,
      middleName,
      lastName,
      cardNumber,
      cardReferenceNumber,
      cardType,
      validToDay,
      validToMonth,
      validToYear,
    } = {}) => (
      <Flex flexWrap="wrap">
        <Box width={1}>
          Full name:
          {` ${joinName({ firstName, middleName, lastName })}`}
        </Box>
        <Box width={1}>
          Number:
          {` ${cardNumber}`}
        </Box>
        <Box width={1}>
          Reference:
          {` ${cardReferenceNumber}`}
        </Box>
        <Box width={1}>
          Type:
          {` ${cardType}`}
        </Box>
        <Box width={1}>
          Expiry:
          {` ${[
            validToDay, validToMonth, validToYear,
          ].filter(identity).map((x) => String(x).padStart(2, '0')).join('/')}`}
        </Box>
      </Flex>
    ),
  },
  {
    title: 'Rebate Details',
    dataIndex: '',
    width: '42%',
    fixed: 'left',
    render: (_, { startCoverDate, medicare = {} } = {}) => (
      <Flex flexWrap="wrap">
        <Box width={1}>
          Do you want to claim the Australian Government Rebate on Private Health Insurance to
          reduce the price you pay for your policy?:
          {' '}
          <FormatBoolean value={medicare.claimRebate} />
        </Box>
        <Box width={1}>
          Are all the people on this policy eligible for Medicare?:
          {' '}
          <FormatBoolean value={medicare.isEntitled} />
        </Box>
        <Box width={1}>
          Are you covered by the policy?:
          {' '}
          <FormatBoolean value={medicare.isPolicyCovered} />
        </Box>
        <Box width={1}>
          I declare that the information I have provided in this form is complete and correct.
          I understand that giving false or misleading information is a serious offence:
          {' '}
          <FormatBoolean value={medicare.declarationAccepted} />
        </Box>
        <Box width={1}>
          Premium reduction commencement:
          {` ${formatDate(startCoverDate)}`}
        </Box>
        <Box width={1}>
          Income rebate tier:
          {` ${medicare.tier || 'Base'}`}
        </Box>
      </Flex>
    ),
  },
];

const MedicareRebatesTable = ({
  logs, ...props
}) => (
  <LogsTable
    scroll={{
      x: 1300,
      y: 490,
    }}
    rowKey={(query) => `${query.id}${query.timestamp}${query.reference}${query.medicare.cardNumber}${query.medicare.cardReferenceNumber}${query.medicare.firstName}${query.medicare.lastName}`}
    columns={medicareRebatesColumns}
    dataSource={logs}
    {...props}
  />
);

export default MedicareRebatesTable;
