import { useMutation } from '@apollo/client';
import AdminResetAdminPassword from '../queries/adminResetAdminPassword.graphql';

export default () => {
  const [mutation, ...rest] = useMutation(AdminResetAdminPassword);

  return [(email) => mutation({
    variables: {
      input: {
        email,
      },
    },
  }), ...rest];
};
