import React from 'react';
import {
  Button, Input, Form, Select, Tag,
} from 'antd';
import { Box } from 'rebass';
import styled from 'styled-components';
import { string } from 'yup';
import { SearchOutlined } from '@ant-design/icons';
import DateRangeFilter from '../../common/molecules/DateRangeFilter';

const MemberIdInput = styled(Input)`
  margin-right: ${(props) => props.theme.space[2]}px;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row;
`;

const statusCodeColor = (value) => ({
  OK: 'green',
  ERROR: 'red',
}[value]);

const ConversionLogsFilters = ({
  form, loading, onSearch, ...props
}) => (
  <Box {...props}>
    <StyledForm
      layout="inline"
      form={form}
      onFinish={({ searchText, ...otherFilters }) => {
        const payload = string().email().isValidSync(searchText) ? {
          email: searchText,
        } : {
          membershipId: searchText,
        };

        onSearch({
          ...payload,
          ...otherFilters,
        });
      }}
    >
      <Form.Item name="searchText">
        <MemberIdInput
          disabled={loading}
          type="text"
          placeholder="Member ID or email"
          suffix={<SearchOutlined type="search" />}
        />
      </Form.Item>

      <Form.Item
        style={{
          width: '30%',
        }}
        name="status"
      >
        <Select
          placeholder="Status"
          mode="multiple"
          disabled={loading}
          tagRender={({ value, label, ...props }) => <Tag color={statusCodeColor(value)} {...props}>{value}</Tag>}
        >
          <Select.Option value="OK">
            <Tag color="green">OK</Tag>
          </Select.Option>
          <Select.Option value="ERROR">
            <Tag color="red">ERROR</Tag>
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name="date">
        <DateRangeFilter />
      </Form.Item>

      <Form.Item>
        <Button loading={loading} htmlType="submit" type="primary">Search</Button>
      </Form.Item>
    </StyledForm>
  </Box>
);

export default ConversionLogsFilters;
