import { ACTIONS, MEMBER_ROLE, POLICY_COVER_STATUS, UNKNOWN_ID } from './constants';

// if persons are searchable they are converted
const isMembershipConverted = (allMembers = []) => !!allMembers.length;

const isMemberOnPolicy = member =>
  member.id !== UNKNOWN_ID && ![
    POLICY_COVER_STATUS.UNKNOWN,
    POLICY_COVER_STATUS.TERMINATED
  ].includes(member.coverStatus);

export const actionsApplicability = {
  [ACTIONS.CHANGE_EMAIL]: (member, allMembers) => isMembershipConverted(allMembers)
      && member.onlineAccess
      && isMemberOnPolicy(member),
  [ACTIONS.RESET_PASSWORD]: (member, allMembers) => isMembershipConverted(allMembers)
      && member.onlineAccess && member.emailVerified && isMemberOnPolicy(member),
  [ACTIONS.UNINVITE_MEMBER]: (member) => member.onlineAccess || member.isRegistered || member.id === UNKNOWN_ID,
  [ACTIONS.INVITE_MEMBER]: (member, allMembers) => isMembershipConverted(allMembers)
      && !member.onlineAccess
      && !member.isRegistered
      && member.id !== UNKNOWN_ID
      && ![MEMBER_ROLE.DEPENDANT].includes(member.role),
  [ACTIONS.UNCONVERT_MEMBERSHIP]: (allMembershipMembers) => {
    // eslint-disable-next-line no-param-reassign
    allMembershipMembers = Array.isArray(allMembershipMembers) ? allMembershipMembers : [allMembershipMembers];
    return isMembershipConverted(allMembershipMembers.filter(member => member.id !== UNKNOWN_ID));
  },
  [ACTIONS.UNLINK_AVANT]: (member) => member.avantLinked,
  [ACTIONS.RESEND_EMAIL_CONFIRMATION]: (member) => !member.emailVerified && member.onlineAccess,
  [ACTIONS.TOGGLE_MFA]: (member) => member.mobilePhone,
};

const getApplicableActions = (member, allMembers = []) => {
  return Object
    .entries(actionsApplicability)
    .reduce((acc, [actionName, checkIfApplicable]) => ({
      ...acc,
      [actionName]: checkIfApplicable(member, allMembers),
    }), {})
};

export default getApplicableActions;
