import { path } from 'lodash/fp';

import HambsLogs from '../../queries/hambsLogs.graphql';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import usePagedQuery from '../../common/hooks/usePagedQuery';


export default () => {
  const [getLogs, { data, ...rest }] = usePagedQuery(HambsLogs, {
    nextLot: path('oms.admin.logs.hambs.next'),
    queryInput: (filters) => ({
      startTime: filters.date.start,
      endTime: filters.date.end,
      membershipId: filters.membershipId,
      requestId: filters.requestId,
    }),
  });

  return [getLogs, {
    data: path('oms.admin.logs.hambs.logs', data)?.map((log, index) => ({
      ...log,
      key: index
    })),
    ...rest,
  }];
};
