import React from 'react';
import { Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';

// eslint-disable-next-line react/prefer-stateless-function,import/prefer-default-export
export class PasswordInput extends React.Component {
  render() {
    return (
      <Input.Password
        size="large"
        prefix={<LockOutlined type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
        type="password"
        placeholder="Password"
        autoComplete="off"
        {...this.props}
      />
    );
  }
}
