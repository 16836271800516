import React from 'react';
import ReactJson from 'react-json-view';
import { Collapse, Tag } from 'antd';
import { formatDateTime } from '../../common/dates';
import LogsTable from '../../common/organisms/LogsTable';

const { Panel } = Collapse;

const columns = ({ onShowAllDetails }) => [
  {
    title: 'Time',
    dataIndex: 'timestamp',
    width: '20%',
    render: formatDateTime,
  },
  {
    title: 'Reference',
    dataIndex: 'reference',
    width: '20%',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '20%',
  },
  {
    title: 'Membership ID',
    dataIndex: 'membershipId',
    width: '20%',
  },
  {
    title: 'Actions',
    width: '20%',
    render: (_, record) => (
      <Tag
        style={{
          cursor: 'pointer',
        }}
        onClick={() => {
          onShowAllDetails(record);
        }}
        color="green"
      >
Show all details
      </Tag>
    ),
  },
];


const MafLogsTable = ({
  logs, onFullDetails, ...props
}) => (
  <LogsTable
    scroll={{
      x: 1300,
      y: 490,
    }}
    onRow={(record) => ({
      onDoubleClick: () => onFullDetails(record),
    })}
    rowKey={(query) => query.id}
    columns={columns({ onShowAllDetails: onFullDetails }).filter((column) => !column.hide)}
    dataSource={logs}
    expandable={{
      expandedRowRender: (record) => (
        <Collapse>
          <Panel header="Request">
            <ReactJson
              displayDataTypes={false}
              displayObjectSize={false}
              src={typeof record.request === 'string' ? JSON.parse(record.request) : record.request}
            />
          </Panel>
          <Panel header="Response">
            <ReactJson
              displayDataTypes={false}
              displayObjectSize={false}
              src={typeof record.response === 'string' ? JSON.parse(record.response) : record.response}
            />
          </Panel>
        </Collapse>
      ),
      rowExpandable: (record) => record.request || record.response,
    }}
    {...props}
  />
);

export default MafLogsTable;
