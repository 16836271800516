import React from 'react';
import useMafLogs from './useMafLogs';
import MafLogsTable from './MafLogsTable';
import FullDetailsModal from './FullDetailsModal';
import MafLogsFilters from './MafLogsFilters';

const MafLogs = () => {
  const mafLogs = useMafLogs();
  return (
    <>
      <MafLogsFilters
        form={mafLogs.state.filtersForm}
        loading={mafLogs.state.loading}
        onSearch={mafLogs.actions.search}
      />
      <MafLogsTable
        onFullDetails={mafLogs.actions.showFullDetails}
        loading={mafLogs.state.loading}
        logs={mafLogs.state.list}
        lots={mafLogs.state.lots}
        currentLotIndex={mafLogs.state.currentLotIndex}
        onLotChange={mafLogs.actions.changeLot}
      />

      <FullDetailsModal
        open={mafLogs.state.fullDetailsModalVisible}
        log={mafLogs.state.selectedLog}
        onOk={mafLogs.actions.closeFullDetails}
        onCancel={mafLogs.actions.closeFullDetails}
      />
    </>
  );
};

export default MafLogs;
